import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { useIsGlobetrotter, useIsMobile } from '@bwoty-web/ui-kit/hooks';
import { changeSelectedProduct, init } from '../redux/actions/bookingstart/bookingstart';
import {
  initPackageSection,
  showDateLayerWithPriceCalendar,
} from '../redux/actions/bookingstart/package';
import { initCitySection } from '../redux/actions/bookingstart/city';
import { initCruiseSection } from '../redux/actions/bookingstart/cruise';
import { initFlightSection } from '../redux/actions/bookingstart/flight';
import selectedProductSelector from '../redux/selectors/selectedProduct';
import { distanceElementTopToDocumentTop } from '../utils/element';
import { ScrollBsIntoViewIfNeeded } from '../utils/scroll';

import PackageSection from './PackageSection';
import CitySection from './CitySection';
import CruiseSection from './CruiseSection';
import FlightSection from './FlightSection';
import ProductSelector from '../components/productSelector/ProductSelector';
import LatestSearches from '../components/latestSearches/LatestSearches';
import BookingstartPlaceholder from '../components/placeholders/bookingstart';
import SearchResultView from '../components/searchResultView/SearchResultView';

import './bookingstart.scss';

const Bookingstart = ({
  preSelectedSettings,
  selectedProduct,
  siteSettings,
  labels,
  dispatch,
  selectedProductData,
  bsInitialized,
  packageSectionInitialized,
  citySectionInitialized,
  cruiseSectionInitialized,
  flightSectionInitialized,
}) => {
  const { hideProductSelector, hideHeading, hideLatestSearches, searchResultMode } =
    preSelectedSettings;

  const { datePicker } = siteSettings;

  const [isSticky, setIsSticky] = useState(false);
  const [isSearchResultMode, setIsSearchResultMode] = useState(searchResultMode);
  const isGlobetrotter = useIsGlobetrotter();
  const isMobile = useIsMobile();

  const initSectionHandler = (index) => {
    switch (index) {
      case 0:
        if (!packageSectionInitialized) {
          dispatch(initPackageSection(preSelectedSettings));
        }
        break;
      case 1:
        if (!citySectionInitialized) {
          dispatch(initCitySection(preSelectedSettings));
        }
        break;
      case 2:
        if (!cruiseSectionInitialized) {
          dispatch(initCruiseSection(preSelectedSettings));
        }
        break;
      case 3:
        if (!flightSectionInitialized) {
          dispatch(initFlightSection(preSelectedSettings));
        }
        break;
      default:
        break;
    }
  };

  const setSelectedSection = (index) => {
    if (selectedProduct !== index) {
      dispatch(changeSelectedProduct(index));
      initSectionHandler(index);
    }
  };

  const handleScroll = () => {
    const bookingStartElement = document.getElementById('bookingstart-app-root');
    const scrollY = window.scrollY > 0 ? window.scrollY : document.documentElement.scrollTop;
    setIsSticky(scrollY > distanceElementTopToDocumentTop(bookingStartElement));
  };

  useEffect(() => {
    const { product } = preSelectedSettings;
    dispatch(init(siteSettings, product));
  }, []);

  useEffect(() => {
    if (isSearchResultMode) {
      document.addEventListener('scroll', () => handleScroll(), false);
    }

    return () => {
      document.removeEventListener('scroll', () => handleScroll(), false);
    };
  }, []);

  // TODO: This should be moved in to respective product form
  useEffect(() => {
    initSectionHandler(selectedProduct);
  }, [bsInitialized]);

  const leaveSearchResultMode = () => {
    setIsSearchResultMode(false);
    ScrollBsIntoViewIfNeeded();
  };

  const getProductForm = () => {
    switch (selectedProduct) {
      case 0:
        return <PackageSection />;
      case 1:
        return <CitySection />;
      case 2:
        return <CruiseSection />;
      case 3:
        return <FlightSection isMobile={isMobile} />;
      default:
        return null;
    }
  };

  const eventMessageHandler = (event) => {
    if (event.origin === window.origin) {
      if (typeof event.data === 'string') {
        if (event.data === 'cmd.open.date') {
          dispatch(showDateLayerWithPriceCalendar());
          leaveSearchResultMode();
        }
      }
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && window) {
      window.addEventListener('message', (e) => eventMessageHandler(e), false);

      return window.removeEventListener('message', (e) => eventMessageHandler(e), false);
    }
  }, []);

  const bookingstartClasses = classNames('bookingstart', {
    'bookingstart--globetrotter': isGlobetrotter,
    'bookingstart--no-heading': hideHeading,
    'bookingstart--no-product-selector': hideProductSelector,
    'bookingstart--isGlobeNLatestSearches':
      isGlobetrotter && !hideLatestSearches && selectedProductData?.searchHistory?.length > 0,
  });

  if (!bsInitialized) {
    return (
      <BookingstartPlaceholder
        hideHeading={hideHeading}
        hideProductSelector={hideProductSelector}
        searchResultMode={isSearchResultMode}
      />
    );
  }

  if (isSearchResultMode) {
    return (
      <SearchResultView
        isLoading={selectedProductData.loading}
        labels={labels}
        data={selectedProductData.compressedData}
        expand={() => leaveSearchResultMode()}
        isSticky={isSticky}
      />
    );
  }

  return (
    <div className={bookingstartClasses}>
      <div className="bs-top-container">
        {!hideHeading && <h2 className="bs-heading">{labels.bookingstartHeading}</h2>}
        {!hideProductSelector && (
          <ProductSelector
            siteSettings={siteSettings}
            labels={labels}
            changeProduct={(index) => setSelectedSection(index)}
            selectedProduct={selectedProduct}
          />
        )}
      </div>
      {getProductForm()}
      {!hideLatestSearches && selectedProductData?.searchHistory?.length > 0 && (
        <LatestSearches
          labels={labels}
          searches={selectedProductData.searchHistory}
          currentSectionName={selectedProductData.sectionName}
          dateSettings={datePicker}
        />
      )}
    </div>
  );
};

Bookingstart.propTypes = {
  preSelectedSettings: PropTypes.shape({
    product: PropTypes.number.isRequired,
    hideProductSelector: PropTypes.bool,
    hideHeading: PropTypes.bool,
    hideLatestSearches: PropTypes.bool,
    searchResultMode: PropTypes.bool,
  }).isRequired,
  selectedProduct: PropTypes.number,
  packageSectionInitialized: PropTypes.bool.isRequired,
  citySectionInitialized: PropTypes.bool.isRequired,
  cruiseSectionInitialized: PropTypes.bool.isRequired,
  flightSectionInitialized: PropTypes.bool.isRequired,
  bsInitialized: PropTypes.bool.isRequired,
  labels: PropTypes.shape({
    bookingstartHeading: PropTypes.string,
  }),
  dispatch: PropTypes.func.isRequired,
  selectedProductData: PropTypes.shape({
    searchHistory: PropTypes.arrayOf(PropTypes.shape({})),
    sectionName: PropTypes.string,
    compressedData: PropTypes.shape({}),
    loading: PropTypes.bool,
  }),
  siteSettings: PropTypes.shape({
    siteId: PropTypes.number.isRequired,
    isGlobetrotter: PropTypes.bool.isRequired,
    alias: PropTypes.string.isRequired,
    apiUrl: PropTypes.string.isRequired,
    datePicker: PropTypes.shape({
      months: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }).isRequired,
};

Bookingstart.defaultProps = {
  labels: undefined,
  selectedProduct: undefined,
  selectedProductData: null,
};

const mapStateToProps = (state) => {
  return {
    ...state.bookingstart,
    selectedProductData: selectedProductSelector(state),
  };
};

export default connect(mapStateToProps)(Bookingstart);
