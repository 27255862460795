import moment from 'moment';
import { createSelector } from 'reselect';
import { getPaxFormText } from '../../utils/pax/helper';
import { getDurationText } from '../../utils/duration/helper';

export const getCountryNameById = (countryList, countryId) => {
  for (let i = 0; i < countryList.length; i += 1) {
    if (countryId === countryList[i].itemId) {
      return countryList[i].name;
    }
  }
  return '';
};

const bookingstartState = state => state.bookingstart;
const packageState = state => state.package;
const cityState = state => state.city;
const cruiseState = state => state.cruise;
const flightState = state => state.flight;
const hotelState = state => state.hotel;

const getSelectedProductData = (bookingstart, packageSection, citySection, cruiseSection, flightSection, hotelSection) => {
  switch (bookingstart.selectedProduct) {
    case 0:
      if (bookingstart.packageSectionInitialized) {
        return {
          ...packageSection,
          compressedData: {
            destination: packageSection.selectedDestination.name,
            country: getCountryNameById(packageSection.countryList, packageSection.selectedDestination.countryId),
            departure: packageSection.selectedDepartureAirport.name,
            duration: getDurationText(packageSection.selectedDuration, packageSection.selectedDepartureDate, packageSection.selectedReturnDate, bookingstart.labels),
            pax: getPaxFormText(packageSection.rooms, bookingstart.labels),
          },
        };
      }
      return packageSection;
    case 1:
      if (bookingstart.citySectionInitialized) {
        return {
          ...citySection,
          compressedData: {
            destination: citySection.selectedResort.name,
            country: null,
            departure: citySection.selectedDepartureAirport.name,
            duration: getDurationText(citySection.selectedDuration, citySection.selectedDepartureDate, citySection.selectedReturnDate, bookingstart.labels),
            pax: getPaxFormText(citySection.rooms, bookingstart.labels),
          },
        };
      }
      return citySection;
    case 2:
      if (bookingstart.cruiseSectionInitialized) {
        return {
          ...cruiseSection,
          compressedData: {
            destination: cruiseSection.selectedArea.name,
            country: null,
            departure: cruiseSection.selectedDeparture.name,
            duration: moment(cruiseSection.selectedDepartureDate).format(bookingstart.siteSettings.datePicker.shortDateFormat),
            pax: getPaxFormText(cruiseSection.rooms, bookingstart.labels),
          },
        };
      }
      return cruiseSection;
    case 3:
      if (bookingstart.flightSectionInitialized) {
        return {
          ...flightSection,
          compressedData: {
            destination: flightSection.selectedDestinationAirport.name,
            country: getCountryNameById(flightSection.countryList, flightSection.selectedDestinationAirport.countryId),
            departure: flightSection.selectedDepartureAirport.name,
            duration: getDurationText(flightSection.selectedDuration, flightSection.selectedDepartureDate, flightSection.selectedReturnDate, bookingstart.labels),
            pax: getPaxFormText([flightSection.selectedPassengerCombination], bookingstart.labels),
          },
        };
      }
      return flightSection;
    case 4:
      if (bookingstart.hotelSectionInitialized) {
        return {
          ...hotelSection,
          compressedData: {
            destination: hotelSection.selectedResort.name,
            country: getCountryNameById(hotelSection.countryList, hotelSection.selectedResort.countryId),
            departure: null,
            duration: getDurationText({ flexibleDuration: true }, hotelSection.selectedCheckInDate, hotelSection.selectedCheckOutDate, bookingstart.labels),
            pax: getPaxFormText(hotelSection.rooms, bookingstart.labels),
          },
        };
      }
      return hotelSection;
    default:
      return null;
  }
};

export default createSelector(
  bookingstartState,
  packageState,
  cityState,
  cruiseState,
  flightState,
  hotelState,
  getSelectedProductData
);
