import PropTypes from 'prop-types';
import DestinationSearchField from './DestinationSearchField';
import DestinationSearchResult from './DestinationSearchResult';

import './destinationSearch.scss';

const DestinationSearch = ({
  onTextSearch,
  searchResult,
  selectDestination,
  searchString,
  setSearchString,
  labels,
}) => {
  const showSearchResult = searchString && searchResult;

  const onTextChange = (input) => {
    setSearchString(input);
    onTextSearch(input);
  };

  return (
    <div className="destination-search-container">
      <DestinationSearchField
        onChange={(input) => onTextChange(input)}
        cancelSearch={() => setSearchString('')}
        searchString={searchString}
        labels={labels}
      />

      {showSearchResult && (
        <DestinationSearchResult
          searchResult={searchResult}
          selectDestination={selectDestination}
          labels={labels}
          searchString={searchString}
          cancelSearch={() => setSearchString('')}
        />
      )}
    </div>
  );
};

DestinationSearch.propTypes = {
  onTextSearch: PropTypes.func.isRequired,
  searchResult: PropTypes.shape({}),
  searchString: PropTypes.string.isRequired,
  setSearchString: PropTypes.func.isRequired,
  selectDestination: PropTypes.func.isRequired,
  labels: PropTypes.shape({}).isRequired,
};

DestinationSearch.defaultProps = {
  searchResult: null,
};

export default DestinationSearch;
