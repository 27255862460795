import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '@bwoty-web/ui-kit';
import { useIsGlobetrotter } from '@bwoty-web/ui-kit/hooks';
import {
  ACTIVITY_BINOCULARS,
  TIME_LOCATION_MUSEUM,
  TRAVEL_AIRPLANE_UP_RIGHT,
  TRAVEL_BRIEFCASE_BAG,
  TRAVEL_SHIP,
} from '@bwoty-web/ui-kit/icons';

import './productSelector.scss';

const ProductSelector = ({ labels, changeProduct, selectedProduct }) => {
  const products = [
    {
      name: labels.sectionHeaderPackage,
      key: 'PACKAGE',
      iconPath: TRAVEL_BRIEFCASE_BAG,
    },
    {
      name: labels.sectionHeaderCity,
      key: 'CITY',
      iconPath: TIME_LOCATION_MUSEUM,
    },
    {
      name: labels.sectionHeaderCruisePackage,
      key: 'CRUISE',
      iconPath: TRAVEL_SHIP,
    },
    {
      name: labels.sectionHeaderFlightOnly,
      key: 'FLIGHT',
      iconPath: TRAVEL_AIRPLANE_UP_RIGHT,
    },
  ];

  const isGlobetrotter = useIsGlobetrotter();
  const showVacationFinderButton =
    labels.sectionHeaderVacationFinder &&
    labels.vacationFinderUrl &&
    !isGlobetrotter;

  return (
    <div className="bs-product-selector">
      {products.map((product, index) => {
        const classes = classNames('bs-product-selector__option', {
          'bs-product-selector__option--active': index === selectedProduct,
        });
        return (
          <button
            type="button"
            key={product.key}
            className={classes}
            onClick={() => changeProduct(index)}
          >
            {product.iconPath && !isGlobetrotter && (
              <Icon
                path={product.iconPath}
                className="bs-product-selector__option-icon"
                aria-hidden="true"
              />
            )}
            <span>{product.name}</span>
          </button>
        );
      })}
      {showVacationFinderButton && (
        <a
          key="VACATION_FINDER"
          className="bs-product-selector__option"
          href={labels.vacationFinderUrl}
        >
          <div className="bs-product-selector__option-icon-container">
            <Icon
              path={ACTIVITY_BINOCULARS}
              className="bs-product-selector__option-icon"
              aria-hidden="true"
            />
          </div>
          <span>{labels.sectionHeaderVacationFinder}</span>
        </a>
      )}
    </div>
  );
};

ProductSelector.propTypes = {
  labels: PropTypes.shape({
    sectionHeaderPackage: PropTypes.string,
    sectionHeaderCity: PropTypes.string,
    sectionHeaderCruisePackage: PropTypes.string,
    sectionHeaderFlightOnly: PropTypes.string,
    sectionHeaderVacationFinder: PropTypes.string,
    vacationFinderUrl: PropTypes.string,
  }).isRequired,
  changeProduct: PropTypes.func.isRequired,
  selectedProduct: PropTypes.number.isRequired,
};

export default ProductSelector;
