import PropTypes from 'prop-types';

import { Icon } from '@bwoty-web/ui-kit';
import { UI_CROSS } from '@bwoty-web/ui-kit/icons';
import { useRef } from 'react';
import { useFocusTrap, useIsGlobetrotter, useIsMobile } from '@bwoty-web/ui-kit/hooks';

import { useCloseOnEscape, useFocusOnFirstFocusableElement, useOnClickOutside } from '../../hooks';

import './selectOverlay.scss';

const SelectOverlay = ({
  children,
  clickedOutside,
  close,
  footerContent,
  headerContent,
  heading,
  headingSubContent,
}) => {
  const contentRef = useRef(null);
  const isMobile = useIsMobile();
  const isGlobetrotter = useIsGlobetrotter();

  useFocusTrap(contentRef);
  useFocusOnFirstFocusableElement(contentRef);
  useOnClickOutside(contentRef, clickedOutside || close, !isMobile);
  useCloseOnEscape(close, contentRef);

  const hideHeading = isGlobetrotter ? !isMobile : false;

  return (
    <div className="select-overlay" ref={contentRef}>
      {!hideHeading && (
        <div className="select-overlay__heading-row">
          <div>
            <div>
              <h3 className="select-overlay__heading2">{heading}</h3>
              {headingSubContent}
            </div>
            {headerContent}
          </div>
          <button
            aria-label="Close"
            className="select-overlay__close-button2"
            lang="en"
            onClick={() => close()}
            type="button"
          >
            <Icon path={UI_CROSS} size="xs" aria-hidden="true" />
          </button>
        </div>
      )}
      <div className="select-overlay__content-container">{children}</div>
      {!!footerContent && <div className="select-overlay__footer-container">{footerContent}</div>}
    </div>
  );
};

SelectOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  clickedOutside: PropTypes.func,
  close: PropTypes.func.isRequired,
  footerContent: PropTypes.node,
  headerContent: PropTypes.node,
  heading: PropTypes.string,
  headingSubContent: PropTypes.node,
};

SelectOverlay.defaultProps = {
  clickedOutside: null,
  footerContent: null,
  headerContent: null,
  heading: '',
  headingSubContent: null,
};

export default SelectOverlay;
