import cx from 'classnames';
import PropTypes from 'prop-types';
import FormField from '../formField/FormField';
import FormFieldLoader from '../formField/FormFieldLoader';
import { getPaxFormText } from '../../utils/pax/helper';

const PaxField = ({
  className,
  labels,
  isLoading,
  isActive,
  isOtherFieldActive,
  toggleOverlay,
  roomDistribution,
  selectedCabinClass,
  children,
}) => {
  const { paxInputLabel, flightClass } = labels;

  const labelAndFlightClass =
    selectedCabinClass && flightClass ? `${paxInputLabel} / ${flightClass}` : paxInputLabel;

  const classes = cx('bs-form__select', 'bs-form__pax-select', className);

  if (isLoading) {
    return (
      <div className={classes}>
        <FormFieldLoader label={paxInputLabel} />
      </div>
    );
  }

  return (
    <div className={classes}>
      <FormField
        value={getPaxFormText(roomDistribution, labels, selectedCabinClass)}
        label={labelAndFlightClass}
        click={() => toggleOverlay()}
        isActive={isActive}
        isOtherFieldActive={isOtherFieldActive}
      />
      {isActive && children}
    </div>
  );
};

PaxField.propTypes = {
  labels: PropTypes.shape({
    paxInputLabel: PropTypes.string,
    flightClass: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isOtherFieldActive: PropTypes.bool.isRequired,
  toggleOverlay: PropTypes.func.isRequired,
  roomDistribution: PropTypes.arrayOf(PropTypes.shape({})),
  selectedCabinClass: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PaxField.defaultProps = {
  className: '',
  roomDistribution: undefined,
  selectedCabinClass: null,
};

export default PaxField;
