/**
 * Handles keyboard navigation for a set of buttons within a column layout.
 *
 * @param {KeyboardEvent} event - The keyboard event triggered by user interaction.
 * @param {number} NUMBER_OF_COLUMNS - The total number of columns in the layout.
 * @param {boolean} [simpleSelect=false] - A flag indicating whether to use a simple selection mode.
 *
 * The function supports navigation using the ArrowUp, ArrowDown, ArrowLeft, and ArrowRight keys.
 * It prevents the default behavior of these keys and moves the focus to the appropriate button
 * based on the key pressed.
 *
 * - ArrowUp: Moves focus to the previous button in the current column.
 * - ArrowDown: Moves focus to the next button in the current column.
 * - ArrowLeft: Moves focus to the corresponding button in the previous column.
 * - ArrowRight: Moves focus to the corresponding button in the next column.
 *
 * The function ensures that focus does not move out of the bounds of the columns or buttons.
 * It also handles edge cases where the next column might have fewer buttons than the current column.
 */
export const DestinationSelectKeyDownHandler = (event, NUMBER_OF_COLUMNS, simpleSelect = false) => {
  const { currentTarget, key } = event;
  const eventKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
  if (!eventKeys.includes(key)) return;
  event.preventDefault();
  const [Up, Down, Left, Right] = eventKeys;
  const { activeElement } = document;

  const currentBtnColNr = Number(activeElement.getAttribute('data-col-nr'));
  let nextColDataNr = currentBtnColNr;
  if (key === Left) nextColDataNr = currentBtnColNr - 1;
  if (key === Right) nextColDataNr = currentBtnColNr + 1;

  if (nextColDataNr < 0 || nextColDataNr >= NUMBER_OF_COLUMNS) return;

  const getColumnButtons = (colNr) =>
    Array.from(
      simpleSelect
        ? currentTarget.querySelectorAll(`button[data-col-nr="${colNr}"]`)
        : currentTarget.querySelectorAll(`ul[data-col-nr="${colNr}"] button`),
    );

  const btnsColumnContainer = [getColumnButtons(currentBtnColNr)];
  if (currentBtnColNr !== nextColDataNr) {
    btnsColumnContainer.push(getColumnButtons(nextColDataNr));
  }

  const [btnsColumn, nextBtnsColumn] = btnsColumnContainer;
  const currentActiveElIndex = btnsColumn.indexOf(activeElement);

  if (key === Up || key === Down) {
    const nextBtn = btnsColumn[currentActiveElIndex + (key === Up ? -1 : 1)];
    if (nextBtn) nextBtn.focus();
  }
  if (key === Left || key === Right) {
    if (currentActiveElIndex >= 0 && currentActiveElIndex < nextBtnsColumn.length) {
      nextBtnsColumn[currentActiveElIndex]?.focus();
    } else {
      const lastNextButton = nextBtnsColumn[nextBtnsColumn.length - 1];
      lastNextButton?.focus();
    }
  }
};

/**
 * Shifts focus to the previous sibling element of a div with the class 'select-overlay'
 * if the keyboard was used to trigger the event.
 *
 * @param {Object} [event={}] - The event object.
 * @param {string} event.type - The type of the event (e.g., 'click').
 * @param {number} event.pageX - The X coordinate of the event relative to the viewport.
 * @param {number} event.pageY - The Y coordinate of the event relative to the viewport.
 */
export const shiftFocusToPreviousSiblingIfKeyBoardWasUsed = ({ type, pageX, pageY } = {}) => {
  const { scrollX, scrollY } = window;
  if (type === 'click' && pageX - scrollX === 0 && pageY - scrollY === 0) {
    document.querySelector('div.select-overlay')?.previousElementSibling?.focus();
  }
};
