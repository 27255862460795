import PropTypes from 'prop-types';

import { useIsGlobetrotter, useIsMobile } from '@bwoty-web/ui-kit/hooks';

import BSButton from '../bsButton/BSButton';

import './durationSelect.scss';

const renderDurationItems = (durations, changeDuration, selectedDuration) => {
  return durations.map((duration) => {
    let isSelected = false;
    if (duration.flexibleDuration) {
      isSelected = selectedDuration.flexibleDuration;
    } else {
      isSelected =
        duration.defaultDuration === selectedDuration.defaultDuration &&
        duration.bookingType === selectedDuration.bookingType &&
        !selectedDuration.flexibleDuration;
    }
    const btnKey = duration?.id || `${duration.bookingType}_${duration.name}`;

    return (
      <BSButton
        key={btnKey}
        name={duration.name}
        onClick={(e) => changeDuration(e, duration)}
        selected={isSelected}
      >
        {duration.name}
      </BSButton>
    );
  });
};

const DurationSelect = ({
  durationList,
  selectedDuration,
  changeDuration,
  labels,
  isFlightDuration,
}) => {
  const isCharterDuration = (dur) => dur.bookingType === 'charter';
  const isIndependentDuration = (dur) => dur.bookingType === 'independent';
  const charterDurations = durationList.filter(isCharterDuration);
  const independentDurations = durationList.filter(isIndependentDuration);

  const isMobile = useIsMobile();
  const isGlobetrotter = useIsGlobetrotter();

  const charterHeader = isFlightDuration
    ? labels.durationCategoryFlightCharter
    : labels.durationCategoryCharter;

  const independentHeader = isFlightDuration
    ? labels.durationCategoryFlightInd
    : labels.durationCategoryInd;

  return (
    <div className="duration-select-container">
      {isGlobetrotter && !isMobile && (
        <h3 className="select-overlay__heading2">{labels.durationLayerHeading}</h3>
      )}
      <div className="duration-select">
        {!!charterDurations.length && (
          <div className="duration-select__category">
            <div className="duration-select__category-header" data-wdio-header-type="charter">
              {charterHeader}
            </div>
            {renderDurationItems(charterDurations, changeDuration, selectedDuration)}
          </div>
        )}
        {!!independentDurations.length && (
          <div className="duration-select__category">
            <div className="duration-select__category-header" data-wdio-header-type="independent">
              {independentHeader}
            </div>
            {renderDurationItems(independentDurations, changeDuration, selectedDuration)}
          </div>
        )}
      </div>
    </div>
  );
};

export default DurationSelect;

DurationSelect.propTypes = {
  durationList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedDuration: PropTypes.shape({
    defaultDuration: PropTypes.number.isRequired,
    flexibleDuration: PropTypes.bool.isRequired,
  }).isRequired,
  changeDuration: PropTypes.func.isRequired,
  labels: PropTypes.shape().isRequired,
  isFlightDuration: PropTypes.bool,
};

DurationSelect.defaultProps = {
  isFlightDuration: false,
};
