import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from '@bwoty-web/ui-kit';

import DateField from '../components/date/DateField';
import DatePicker from '../components/date/DatePicker';
import DepartureField from '../components/departure/DepartureField';
import DepartureSelect from '../components/departure/DepartureSelect';
import DestinationField from '../components/destination/DestinationField';
import DestinationSimpleSelect from '../components/destination/DestinationSimpleSelect';
import PaxField from '../components/pax/PaxField';
import PaxSelect from '../components/pax/PaxSelect';
import SelectOverlay from '../components/selectOverlay/SelectOverlay';

import { maxNumberOfPax } from '../constants/pax';
import { validRoomDistribution } from '../utils/pax/validation';
import { ScrollElementToBottomOfScreen } from '../utils/scroll';
import { shiftFocusToPreviousSiblingIfKeyBoardWasUsed } from '../utils/keyboardHandler';

import * as CruiseActions from '../redux/actions/bookingstart/cruise';
import * as BookingstartActions from '../redux/actions/bookingstart/bookingstart';
import * as cruiseService from '../services/cruise';

class CruiseSection extends Component {
  constructor(props) {
    super();

    this.state = {
      paxIsValid: true,
      showDateLayer: false,
      showDepartureLayer: false,
      showDestinationLayer: false,
      showDurationLayer: false,
      showPaxLayer: false,
    };

    this.departureRange = {
      min: moment().add(props.siteSettings.datePicker.cruiseBookDaysAhead, 'days'),
      max: moment().add(props.siteSettings.datePicker.cruiseMaxDaysToDeparture, 'days'),
    };

    this.setDate = this.setDate.bind(this);
    this.toggleDateLayer = this.toggleDateLayer.bind(this);
  }

  setDeparture(e, departure) {
    const { dispatch } = this.props;
    dispatch(CruiseActions.changeDeparture(departure));
    shiftFocusToPreviousSiblingIfKeyBoardWasUsed(e);
    this.toggleDepartureLayer();
  }

  setArea(e, area) {
    const { dispatch } = this.props;
    dispatch(CruiseActions.changeArea(area));
    shiftFocusToPreviousSiblingIfKeyBoardWasUsed(e);
    this.toggleDestinationLayer();
  }

  setDate(departureDate) {
    const { dispatch } = this.props;
    dispatch(CruiseActions.changeDepartureDate(departureDate));
  }

  setRoomDistribution(e, roomDistribution) {
    const { dispatch } = this.props;
    dispatch(CruiseActions.changeRoomDistribution(roomDistribution));
    shiftFocusToPreviousSiblingIfKeyBoardWasUsed(e);
    this.togglePaxLayer();
  }

  togglePaxLayer(paxIsValid = true) {
    this.setState((prevState) => ({
      showPaxLayer: !prevState.showPaxLayer,
      paxIsValid,
    }));
    if (window.innerWidth < 768) {
      ScrollElementToBottomOfScreen(document.getElementsByClassName('bs-form__search-button')[0]);
    }
  }

  toggleDateLayer() {
    this.setState((prevState) => ({ showDateLayer: !prevState.showDateLayer }));
  }

  toggleDestinationLayer() {
    this.setState((prevState) => ({ showDestinationLayer: !prevState.showDestinationLayer }));
  }

  toggleDepartureLayer() {
    this.setState((prevState) => ({ showDepartureLayer: !prevState.showDepartureLayer }));
  }

  isSelectOverlayOpen() {
    const {
      showDateLayer,
      showDepartureLayer,
      showDestinationLayer,
      showDurationLayer,
      showPaxLayer,
    } = this.state;
    return (
      showDepartureLayer ||
      showDurationLayer ||
      showDateLayer ||
      showDestinationLayer ||
      showPaxLayer
    );
  }

  navigateToResultPage() {
    const { dispatch } = this.props;
    dispatch(BookingstartActions.searchInit());

    cruiseService.getSearchUrl(this.props).then((url) => {
      window.location.href = url;
    });
  }

  searchCruise() {
    const { showPaxLayer } = this.state;
    const { roomDistribution } = this.props;
    if (showPaxLayer) {
      // Wait to the pax layer is closed
      setTimeout(() => {
        if (validRoomDistribution(roomDistribution)) {
          this.navigateToResultPage();
        } else {
          this.togglePaxLayer(false);
        }
      }, 10);
    } else if (validRoomDistribution(roomDistribution)) {
      this.navigateToResultPage();
    } else {
      this.togglePaxLayer(false);
    }
  }

  render() {
    const {
      cruiseAreas,
      departureDates,
      departureList,
      labels,
      loading,
      roomDistribution,
      selectedArea,
      selectedDeparture,
      selectedDepartureDate,
      selectedNumberOfRooms,
      siteSettings,
    } = this.props;

    const {
      paxIsValid,
      showDateLayer,
      showDepartureLayer,
      showDestinationLayer,
      showDurationField,
      showPaxLayer,
    } = this.state;

    const cruiseSectionClasses = cx('bs-form', 'bs-form--cruise', {
      'bs-form--no-duration-field': !showDurationField,
    });

    return (
      <div className={cruiseSectionClasses}>
        <DepartureField
          isActive={showDepartureLayer}
          isLoading={loading}
          isOtherFieldActive={!showDepartureLayer && this.isSelectOverlayOpen()}
          labels={labels}
          selectedAirport={selectedDeparture}
          toggleOverlay={() => this.toggleDepartureLayer()}
        >
          {!loading && (
            <SelectOverlay
              close={() => this.toggleDepartureLayer()}
              heading={labels.departureLayerHeading}
            >
              <DepartureSelect
                airportList={departureList}
                changeAirport={(e, departure) => this.setDeparture(e, departure)}
                heading={labels.departureLayerHeading}
                selectedAirport={selectedDeparture}
              />
            </SelectOverlay>
          )}
        </DepartureField>

        <DestinationField
          isActive={showDestinationLayer}
          isLoading={loading}
          isOtherFieldActive={!showDestinationLayer && this.isSelectOverlayOpen()}
          labels={labels}
          selectedDestination={selectedArea}
          toggleOverlay={() => this.toggleDestinationLayer()}
        >
          {!loading && (
            <SelectOverlay
              close={() => this.toggleDestinationLayer()}
              heading={labels.destinationLayerHeading}
            >
              <DestinationSimpleSelect
                destinationList={cruiseAreas}
                labels={labels}
                selectedDestination={selectedArea}
                setDestination={(e, area) => this.setArea(e, area)}
              />
            </SelectOverlay>
          )}
        </DestinationField>

        <DateField
          datePickerIsActive={showDateLayer}
          dateSettings={siteSettings.datePicker}
          hideReturnDate
          isActive={showDateLayer}
          isHotelOnly={false}
          isLoading={loading}
          isOtherFieldActive={!showDateLayer && this.isSelectOverlayOpen()}
          labels={labels}
          selectedDepartureDate={selectedDepartureDate}
          showDateField
          toggleOverlay={() => this.toggleDateLayer()}
        >
          {!loading && showDateLayer && (
            <DatePicker
              changeDate={this.setDate}
              close={this.toggleDateLayer}
              dateSettings={siteSettings.datePicker}
              isHidden={!showDateLayer}
              isHotelOnly={false}
              labels={labels}
              onlySelectableDates
              range={this.departureRange}
              selectableDepartureDates={departureDates}
              selectedDepartureDate={selectedDepartureDate}
              selectedDuration={{ flexibleDuration: false }}
              siteId={siteSettings.siteId}
            />
          )}
        </DateField>

        <PaxField
          isActive={showPaxLayer}
          isLoading={loading}
          isOtherFieldActive={!showPaxLayer && this.isSelectOverlayOpen()}
          labels={labels}
          roomDistribution={roomDistribution}
          showPaxField
          toggleOverlay={() => this.togglePaxLayer()}
        >
          {!loading && (
            <PaxSelect
              activeValidation={!paxIsValid}
              changeRoomDistribution={(e, rooms) => this.setRoomDistribution(e, rooms)}
              close={() => this.togglePaxLayer()}
              hideSameRoomCheckbox
              isCruise
              labels={labels}
              maxNumberOfPax={maxNumberOfPax.cruise}
              multipleRooms={false}
              numberOfRooms={selectedNumberOfRooms}
              preferAllPaxInSameRoom={false}
              roomDistribution={roomDistribution}
            />
          )}
        </PaxField>

        <div className="bs-form__button-container">
          <Button
            variant="primary"
            disabled={loading}
            onClick={() => this.searchCruise()}
            rel="nofollow"
            className="bs-form__search-button"
          >
            {labels.searchButtonText}
          </Button>
        </div>
      </div>
    );
  }
}

CruiseSection.propTypes = {
  cruiseAreas: PropTypes.arrayOf(PropTypes.shape({})),
  departureDates: PropTypes.arrayOf(PropTypes.string),
  departureList: PropTypes.arrayOf(PropTypes.shape({})),
  dispatch: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    departureLayerHeading: PropTypes.string.isRequired,
    destinationLayerHeading: PropTypes.string.isRequired,
    searchButtonText: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  roomDistribution: PropTypes.arrayOf(PropTypes.shape({})),
  selectedArea: PropTypes.shape({}),
  selectedDeparture: PropTypes.shape({}),
  selectedDepartureDate: PropTypes.string,
  selectedNumberOfRooms: PropTypes.number,
  siteSettings: PropTypes.shape({
    datePicker: PropTypes.shape({
      cruiseBookDaysAhead: PropTypes.number.isRequired,
      cruiseMaxDaysToDeparture: PropTypes.number.isRequired,
    }).isRequired,
    siteId: PropTypes.number,
  }).isRequired,
};

CruiseSection.defaultProps = {
  cruiseAreas: undefined,
  departureDates: undefined,
  departureList: undefined,
  roomDistribution: undefined,
  selectedArea: undefined,
  selectedDeparture: undefined,
  selectedDepartureDate: undefined,
  selectedNumberOfRooms: undefined,
};

function select(state) {
  return {
    loading: state.cruise.loading || !state.bookingstart.cruiseSectionInitialized,
    siteSettings: state.bookingstart.siteSettings,
    labels: state.bookingstart.labels,
    departureList: state.cruise.departureList,
    cruiseAreas: state.cruise.cruiseAreas,
    departureDates: state.cruise.departureDates,
    roomDistribution: state.cruise.rooms,
    selectedDeparture: state.cruise.selectedDeparture,
    selectedArea: state.cruise.selectedArea,
    selectedDepartureDate: state.cruise.selectedDepartureDate,
    selectedNumberOfRooms: state.cruise.selectedNumberOfRooms,
    type: state.cruise.type,
    searchHistory: state.cruise.searchHistory,
  };
}

export default connect(select)(CruiseSection);
