import moment from 'moment';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { Button } from '@bwoty-web/ui-kit';
import { connect } from 'react-redux';
import { Component } from 'react';

import bookingTypes from '../constants/bookingTypes';
import DateField from '../components/date/DateField';
import DatePicker from '../components/date/DatePicker';
import DepartureField from '../components/departure/DepartureField';
import DepartureSelect from '../components/departure/DepartureSelect';
import DestinationField from '../components/destination/DestinationField';
import DestinationSimpleSelect from '../components/destination/DestinationSimpleSelect';
import DurationField from '../components/duration/DurationField';
import DurationSelect from '../components/duration/DurationSelect';
import PaxField from '../components/pax/PaxField';
import PaxSelect from '../components/pax/PaxSelect';
import SelectOverlay from '../components/selectOverlay/SelectOverlay';

import { maxNumberOfPax } from '../constants/pax';
import { validRoomDistribution } from '../utils/pax/validation';
import { getPriceCalendarDataKey } from '../utils/actionHelper';
import { shiftFocusToPreviousSiblingIfKeyBoardWasUsed } from '../utils/keyboardHandler';
import { ScrollBsIntoViewIfNeeded, ScrollElementToBottomOfScreen } from '../utils/scroll';

import * as CityActions from '../redux/actions/bookingstart/city';
import * as cityService from '../services/city';
import * as BookingstartActions from '../redux/actions/bookingstart/bookingstart';

const showMonthCheckbox = (bookingType) => {
  return bookingType === 'charter';
};

class CitySection extends Component {
  constructor(props) {
    super();
    this.state = {
      showDepartureLayer: false,
      showDurationLayer: false,
      showDateLayer: false,
      showDestinationLayer: false,
      showPaxLayer: false,
      showDurationField: this.showDurationField(props.durationList),
      dateSelectionInProgress: false,
      departureDateDuringSelection: null,
      returnDateDuringSelection: null,
      paxIsValid: true,
    };

    this.independentRange = {
      min: moment().add(props.siteSettings.datePicker.dpBookDaysAhead, 'days'),
      max: moment().add(props.siteSettings.datePicker.dpMaxDaysToReturn, 'days'),
    };

    this.charterRange = {
      min: moment(),
      max: moment().add(19, 'month').endOf('month'),
    };

    this.setDate = this.setDate.bind(this);
    this.toggleDateLayer = this.toggleDateLayer.bind(this);
    this.setDateSelectionInProgress = this.setDateSelectionInProgress.bind(this);
    this.setDepartureDateDuringSelection = this.setDepartureDateDuringSelection.bind(this);
    this.setReturnDateDuringSelection = this.setReturnDateDuringSelection.bind(this);
    this.setDepartureMonths = this.setDepartureMonths.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      showDurationField: this.showDurationField(props.durationList),
    });
  }

  showDurationField(durationList) {
    if (!durationList) {
      return true;
    }

    const numberOfDurationTypes = durationList.length;
    return (
      numberOfDurationTypes > 1 ||
      (numberOfDurationTypes === 1 && !durationList[0].flexibleDuration)
    );
  }

  getDateRange(bookingType) {
    return bookingType == 'independent' ? this.independentRange : this.charterRange;
  }

  setDepartureAirport(e, airport) {
    const { dispatch } = this.props;
    dispatch(CityActions.changeDepartureAirport(airport));
    shiftFocusToPreviousSiblingIfKeyBoardWasUsed(e);
    this.toggleDepartureLayer();
  }

  setDepartureMonths(months) {
    const { dispatch } = this.props;
    dispatch(CityActions.changeDepartureMonths(months));
  }

  setDestination(e, destination) {
    const { dispatch } = this.props;
    dispatch(CityActions.changeResort(destination));
    shiftFocusToPreviousSiblingIfKeyBoardWasUsed(e);
    this.toggleDestinationLayer();
    ScrollBsIntoViewIfNeeded();
  }

  setDestinationFreeText(e, destination) {
    const { dispatch } = this.props;
    dispatch(CityActions.changeResort(destination));
    shiftFocusToPreviousSiblingIfKeyBoardWasUsed(e);
    this.toggleDestinationLayer();
  }

  setDuration(e, duration) {
    const { dispatch } = this.props;
    dispatch(CityActions.changeDuration(duration));
    shiftFocusToPreviousSiblingIfKeyBoardWasUsed(e);
    this.toggleDurationLayer();
  }

  setDate(departureDate, returnDate) {
    const { dispatch } = this.props;
    if (returnDate != null) {
      dispatch(CityActions.changeDates(departureDate, returnDate));
    } else {
      dispatch(CityActions.changeDepartureDate(departureDate));
    }
  }

  setRoomDistribution(e, roomDistribution, selectedNumberOfRooms, isSameRoom) {
    const { dispatch } = this.props;
    dispatch(
      CityActions.changeRoomDistribution(roomDistribution, selectedNumberOfRooms, isSameRoom),
    );
    shiftFocusToPreviousSiblingIfKeyBoardWasUsed(e);
    this.togglePaxLayer();
  }

  searchDestinations(searchTerm) {
    const { dispatch, selectedDepartureAirport } = this.props;
    dispatch(CityActions.searchDestinations(selectedDepartureAirport.itemId, searchTerm));
  }

  navigateToResultPage() {
    const { dispatch } = this.props;
    dispatch(BookingstartActions.searchInit());

    cityService.getSearchUrl(this.props).then((url) => {
      window.location.href = url;
    });
  }

  searchPackage() {
    const { showPaxLayer } = this.state;
    const { roomDistribution } = this.props;
    if (showPaxLayer) {
      // Wait to the pax layer is closed
      setTimeout(() => {
        if (validRoomDistribution(roomDistribution)) {
          this.navigateToResultPage();
        } else {
          this.togglePaxLayer(false);
        }
      }, 10);
    } else if (validRoomDistribution(roomDistribution)) {
      this.navigateToResultPage();
    } else {
      this.togglePaxLayer(false);
    }
  }

  isSelectOverlayOpen() {
    const {
      showDateLayer,
      showDepartureLayer,
      showDestinationLayer,
      showDurationLayer,
      showPaxLayer,
    } = this.state;
    return (
      showDepartureLayer ||
      showDurationLayer ||
      showDateLayer ||
      showDestinationLayer ||
      showPaxLayer
    );
  }

  toggleDepartureLayer() {
    this.setState((prevState) => ({ showDepartureLayer: !prevState.showDepartureLayer }));
  }

  toggleDestinationLayer() {
    this.setState((prevState) => ({ showDestinationLayer: !prevState.showDestinationLayer }));
  }

  toggleDurationLayer() {
    this.setState((prevState) => ({ showDurationLayer: !prevState.showDurationLayer }));
  }

  toggleDateLayer() {
    const { returnDateDuringSelection, showDateLayer } = this.state;
    if (showDateLayer && returnDateDuringSelection == null) {
      this.setDateSelectionInProgress(false);
      this.setDepartureDateDuringSelection(null);
    }
    this.setState((prevState) => ({ showDateLayer: !prevState.showDateLayer }));
  }

  togglePaxLayer(paxIsValid = true) {
    this.setState((prevState) => ({
      showPaxLayer: !prevState.showPaxLayer,
      paxIsValid,
    }));
    if (window.innerWidth < 768) {
      ScrollElementToBottomOfScreen(document.getElementsByClassName('bs-form__search-button')[0]);
    }
  }

  setDepartureDateDuringSelection(departureDate) {
    this.setState({ departureDateDuringSelection: departureDate });
  }

  setReturnDateDuringSelection(returnDate) {
    this.setState({ returnDateDuringSelection: returnDate });
  }

  setDateSelectionInProgress(dateSelectionInProgress) {
    this.setState({ dateSelectionInProgress });
  }

  setShowPriceCalendar(show) {
    const { dispatch } = this.props;
    dispatch(CityActions.setShowPriceCalendar(show));
  }

  changeDurationForPriceCalendar(priceCalendarDuration) {
    const { dispatch, durationList, selectedDuration } = this.props;

    const priceCalendarDurationIncludedInDurations = durationList.find(
      (duration) =>
        !duration.flexibleDuration &&
        duration.defaultDuration === priceCalendarDuration.defaultDuration,
    );
    const durationToSelect = priceCalendarDurationIncludedInDurations || priceCalendarDuration;

    dispatch(CityActions.setPreviousDuration(selectedDuration));
    dispatch(CityActions.changeDuration(durationToSelect, false));
  }

  getCurrentPriceCalendarData() {
    const {
      selectedDepartureAirport,
      selectedResort,
      selectedDuration,
      priceCalendarData,
      showPriceCalendar,
      showDirectFlights,
    } = this.props;

    const shouldShowPrices = showPriceCalendar && selectedDuration.hasCalendar;
    if (!shouldShowPrices) {
      return [];
    }
    const key = getPriceCalendarDataKey(
      selectedDepartureAirport.itemId,
      selectedResort.itemId,
      0,
      selectedDuration,
      showDirectFlights,
    );
    return priceCalendarData[key];
  }

  render() {
    const {
      dispatch,
      siteSettings,
      labels,
      isLoading,
      selectedDepartureAirport,
      departureAirportList,
      selectedResort,
      searchResultsDestination,
      durationList,
      selectedDuration,
      roomDistribution,
      selectedNumberOfRooms,
      isEveryoneInSameRoomChecked,
      selectedDepartureDate,
      selectedReturnDate,
      departureDates,
      resortList,
      selectedMonths,
      showPriceCalendar,
      showDirectFlights,
      priceCalendarDurationOptions,
      previousDuration,
      showCabinClasses,
      selectedCabinClass,
      showDirectOnly,
      searchDirectOnly,
      showLuggageIncluded,
      searchLuggageIncluded,
    } = this.props;
    const {
      showDepartureLayer,
      showDestinationLayer,
      showDurationLayer,
      showDurationField,
      showPaxLayer,
      showDateLayer,
      paxIsValid,
      dateSelectionInProgress,
      departureDateDuringSelection,
      returnDateDuringSelection,
    } = this.state;

    const citySectionClasses = ClassNames({
      'bs-form': true,
      'bs-form--city': true,
      'bs-form--no-duration-field': !showDurationField,
    });

    return (
      <div className={citySectionClasses}>
        <DepartureField
          labels={labels}
          isLoading={isLoading}
          isActive={showDepartureLayer}
          isOtherFieldActive={!showDepartureLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.toggleDepartureLayer()}
          selectedAirport={selectedDepartureAirport}
        >
          {!isLoading && (
            <SelectOverlay
              heading={labels.departureLayerHeading}
              close={() => this.toggleDepartureLayer()}
            >
              <DepartureSelect
                heading={labels.departureLayerHeading}
                airportList={departureAirportList}
                selectedAirport={selectedDepartureAirport}
                changeAirport={(e, airport) => this.setDepartureAirport(e, airport)}
              />
            </SelectOverlay>
          )}
        </DepartureField>

        <DestinationField
          labels={labels}
          isLoading={isLoading}
          isActive={showDestinationLayer}
          isOtherFieldActive={!showDestinationLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.toggleDestinationLayer()}
          selectedDestination={selectedResort}
        >
          {!isLoading && (
            <SelectOverlay
              heading={labels.destinationLayerHeading}
              close={() => this.toggleDestinationLayer()}
            >
              <DestinationSimpleSelect
                destinationList={resortList}
                selectedDestination={selectedResort}
                setDestination={(e, destination) => this.setDestination(e, destination)}
                setDestinationBySearch={(e, destination) => this.setDestinationFreeText(e, destination)}
                searchDestinations={(searchString) => this.searchDestinations(searchString)}
                searchResult={searchResultsDestination}
                labels={labels}
              />
            </SelectOverlay>
          )}
        </DestinationField>

        <DurationField
          labels={labels}
          isLoading={isLoading}
          isActive={showDurationLayer}
          isOtherFieldActive={!showDurationLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.toggleDurationLayer()}
          selectedDuration={selectedDuration}
          showDurationField={showDurationField}
        >
          {!isLoading && (
            <SelectOverlay
              heading={labels.durationLayerHeading}
              close={() => this.toggleDurationLayer()}
            >
              <DurationSelect
                durationList={durationList}
                selectedDuration={selectedDuration}
                changeDuration={(e, duration) => this.setDuration(e, duration)}
                labels={labels}
              />
            </SelectOverlay>
          )}
        </DurationField>

        <DateField
          dateSettings={siteSettings.datePicker}
          labels={labels}
          isLoading={isLoading}
          isActive={showDateLayer}
          isOtherFieldActive={!showDateLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.toggleDateLayer()}
          selectedDepartureDate={selectedDepartureDate}
          selectedReturnDate={selectedReturnDate}
          selectedDuration={selectedDuration}
          datePickerIsActive={showDateLayer}
          dateSelectionInProgress={dateSelectionInProgress}
          setDateSelectionInProgress={(selectionInProgress) =>
            this.setDateSelectionInProgress(selectionInProgress)
          }
          departureDateDuringSelection={departureDateDuringSelection}
          returnDateDuringSelection={returnDateDuringSelection}
          isHotelOnly={false}
          selectedMonths={selectedMonths}
        >
          {!isLoading && showDateLayer && (
            <DatePicker
              dateSettings={siteSettings.datePicker}
              selectedDepartureDate={selectedDepartureDate}
              selectedReturnDate={selectedReturnDate}
              range={this.getDateRange(selectedDuration.bookingType)}
              changeDate={this.setDate}
              selectableDepartureDates={departureDates}
              selectedDuration={selectedDuration}
              confirmButtonLabel={labels.datePickerConfirmLabel}
              close={() => this.toggleDateLayer()}
              isHidden={!showDateLayer}
              labels={labels}
              setDateSelectionInProgress={this.setDateSelectionInProgress}
              setDepartureDateDuringSelection={this.setDepartureDateDuringSelection}
              setReturnDateDuringSelection={this.setReturnDateDuringSelection}
              isHotelOnly={false}
              monthSelector={{
                months: selectedMonths,
                set: this.setDepartureMonths,
                show: showMonthCheckbox(selectedDuration.bookingType),
              }}
              showPriceCalendar={showPriceCalendar}
              showDirectFlights={showDirectFlights}
              priceCalendarDurationOptions={priceCalendarDurationOptions}
              changeDurationForPriceCalendar={(duration) =>
                this.changeDurationForPriceCalendar(duration)
              }
              setShowPriceCalendar={(show) => this.setShowPriceCalendar(show)}
              setShowDirectFlights={(show) => dispatch(CityActions.setShowDirectFlights(show))}
              priceCalendarData={this.getCurrentPriceCalendarData()}
              previousDuration={previousDuration}
              changeDuration={(duration) => dispatch(CityActions.changeDuration(duration))}
              siteId={siteSettings.siteId}
              showDirectOnly={showDirectOnly}
              searchDirectOnly={searchDirectOnly}
              setSearchDirectOnly={(toggle) => dispatch(CityActions.setSearchDirectOnly(toggle))}
              showLuggageIncluded={showLuggageIncluded}
              searchLuggageIncluded={searchLuggageIncluded}
              setSearchLuggageIncluded={(toggle) =>
                dispatch(CityActions.setSearchLuggageIncluded(toggle))
              }
              selectedCabinClass={selectedCabinClass}
            />
          )}
        </DateField>

        <PaxField
          labels={labels}
          isLoading={isLoading}
          isActive={showPaxLayer}
          isOtherFieldActive={!showPaxLayer && this.isSelectOverlayOpen()}
          toggleOverlay={() => this.togglePaxLayer()}
          roomDistribution={roomDistribution}
          selectedCabinClass={selectedCabinClass}
        >
          {!isLoading && (
            <PaxSelect
              labels={labels}
              numberOfRooms={selectedNumberOfRooms}
              roomDistribution={roomDistribution}
              changeRoomDistribution={(e, rooms, numberOfRooms, isSameRoom) => {
                this.setRoomDistribution(e, rooms, numberOfRooms, isSameRoom);
              }}
              multipleRooms={selectedDuration.bookingType === bookingTypes.dp}
              preferAllPaxInSameRoom={isEveryoneInSameRoomChecked}
              maxNumberOfPax={maxNumberOfPax.charter}
              activeValidation={!paxIsValid}
              close={() => this.togglePaxLayer()}
              showCabinClasses={showCabinClasses}
              selectedCabinClass={selectedCabinClass}
              setCabinClass={(cabinclass) => dispatch(CityActions.setCabinClass(cabinclass))}
              isDP={selectedDuration.bookingType === bookingTypes.dp}
            />
          )}
        </PaxField>

        <div className="bs-form__button-container">
          <Button
            variant="primary"
            disabled={isLoading}
            onClick={() => this.searchPackage()}
            rel="nofollow"
            className="bs-form__search-button"
          >
            {labels.searchButtonText}
          </Button>
        </div>
      </div>
    );
  }
}

CitySection.propTypes = {
  departureAirportList: PropTypes.arrayOf(PropTypes.shape()),
  departureDates: PropTypes.arrayOf(PropTypes.string),
  dispatch: PropTypes.func.isRequired,
  durationList: PropTypes.arrayOf(PropTypes.shape()),
  isEveryoneInSameRoomChecked: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  labels: PropTypes.shape().isRequired,
  previousDuration: PropTypes.shape(),
  priceCalendarData: PropTypes.shape(),
  priceCalendarDurationOptions: PropTypes.arrayOf(PropTypes.shape()),
  resortList: PropTypes.arrayOf(PropTypes.shape()),
  roomDistribution: PropTypes.arrayOf(PropTypes.shape()),
  searchDirectOnly: PropTypes.bool,
  searchLuggageIncluded: PropTypes.bool,
  searchResultsDestination: PropTypes.shape({}),
  selectedCabinClass: PropTypes.string,
  selectedDepartureAirport: PropTypes.shape(),
  selectedDepartureDate: PropTypes.string,
  selectedDuration: PropTypes.shape({
    bookingType: PropTypes.string.isRequired,
    defaultDuration: PropTypes.number.isRequired,
    flexibleDuration: PropTypes.bool.isRequired,
    hasCalendar: PropTypes.bool.isRequired,
  }),
  selectedMonths: PropTypes.arrayOf(PropTypes.shape({})),
  selectedNumberOfRooms: PropTypes.number,
  selectedResort: PropTypes.shape(),
  selectedReturnDate: PropTypes.string,
  showCabinClasses: PropTypes.arrayOf(PropTypes.string),
  showDirectFlights: PropTypes.bool,
  showDirectOnly: PropTypes.bool,
  showLuggageIncluded: PropTypes.bool,
  showPriceCalendar: PropTypes.bool,
  siteSettings: PropTypes.shape().isRequired,
};

CitySection.defaultProps = {
  departureAirportList: undefined,
  departureDates: undefined,
  durationList: undefined,
  isEveryoneInSameRoomChecked: undefined,
  previousDuration: null,
  priceCalendarData: undefined,
  priceCalendarDurationOptions: undefined,
  resortList: undefined,
  roomDistribution: undefined,
  searchDirectOnly: false,
  searchLuggageIncluded: false,
  searchResultsDestination: undefined,
  selectedCabinClass: null,
  selectedDepartureAirport: undefined,
  selectedDepartureDate: undefined,
  selectedDuration: undefined,
  selectedMonths: undefined,
  selectedNumberOfRooms: undefined,
  selectedResort: undefined,
  selectedReturnDate: undefined,
  showCabinClasses: [],
  showDirectFlights: undefined,
  showDirectOnly: false,
  showLuggageIncluded: false,
  showPriceCalendar: undefined,
};

function select(state) {
  return {
    isLoading: state.city.loading || !state.bookingstart.citySectionInitialized,
    siteSettings: state.bookingstart.siteSettings,
    departureAirportList: state.city.departureAirportList,
    selectedDepartureAirport: state.city.selectedDepartureAirport,
    resortList: state.city.resortList,
    selectedResort: state.city.selectedResort,
    durationList: state.city.durationList,
    selectedDuration: state.city.selectedDuration,
    nOfRoomsList: state.city.nOfRoomsList,
    selectedNumberOfRooms: state.city.selectedNumberOfRooms,
    selectedDepartureDate: state.city.selectedDepartureDate,
    selectedReturnDate: state.city.selectedReturnDate,
    isEveryoneInSameRoomChecked: state.city.isEveryoneInSameRoomChecked,
    searchResultsDestination: state.city.searchResultsDestination,
    departureDates: state.city.departureDates,
    returnDates: state.city.returnDates,
    roomDistribution: state.city.rooms,
    labels: state.bookingstart.labels,
    selectedMonths: state.city.departureMonths,
    showPriceCalendar: state.city.showPriceCalendar,
    showDirectFlights: state.city.showDirectFlights,
    priceCalendarDurationOptions: state.city.priceCalendarDurationOptions,
    priceCalendarData: state.city.priceCalendarData,
    previousDuration: state.city.previousDuration,
    selectedOffer: state.city.selectedOffer,
    showDirectOnly: state.city.showDirectOnly,
    searchDirectOnly: state.city.searchDirectOnly,
    showLuggageIncluded: state.city.showLuggageIncluded,
    searchLuggageIncluded: state.city.searchLuggageIncluded,
    showCabinClasses: state.city.showCabinClasses,
    selectedCabinClass: state.city.selectedCabinClass,
  };
}

export default connect(select)(CitySection);
