import { useEffect } from 'react';

const useFocusOnFirstFocusableElement = (ref) => {
  useEffect(() => {
    const contentElement = ref.current;
    const focusableElements = contentElement.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"], audio[controls], video[controls], [contenteditable])',
    );

    const filteredFocusableElements = [...focusableElements].filter((el) => {
      const computedStyle = window.getComputedStyle(el);

      return (
        !(el.offsetWidth === 0 && el.offsetHeight === 0) &&
        computedStyle.visibility !== 'hidden' &&
        computedStyle.display !== 'none'
      );
    });
    const firstFocusable = filteredFocusableElements[0];
    firstFocusable.focus();
  }, [ref]);
};

export default useFocusOnFirstFocusableElement;
