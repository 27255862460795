import { Component } from 'react';
import PropTypes from 'prop-types';
import { isLargeScreen } from '../../utils/device';

import './destinationSearchResult.scss';

const getItemPresentationText = function (itemName, searchString) {
  const words = itemName.split(' ');
  const itemPresentationText = [];

  for (let i = 0; i < words.length; i += 1) {
    if (words[i].substr(0, searchString.length).toLowerCase() == searchString.toLowerCase()) {
      itemPresentationText.push(
        <span key={i}>
          <strong>{words[i].substr(0, searchString.length)}</strong>
          {`${words[i].substr(searchString.length, words[i].length)} `}
        </span>
      );
    } else {
      itemPresentationText.push(<span key={i}>{`${words[i]} `}</span>);
    }
  }
  return itemPresentationText;
};

const SearchResultItem = ({ item, selectDestination, searchString }) => {
  const name = item.searchName ? item.searchName : item.name;
  return (
    <button
      className="destination-search-result__item"
      onClick={(e) => selectDestination(e, item)}
      type="button"
    >
      {getItemPresentationText(name, searchString)}
    </button>
  );
};

const CountryCategory = ({ labels, searchResult, ...otherProps }) => {
  if (searchResult.countries.length < 1) {
    return null;
  }

  return (
    <div className="destination-search-result__countries">
      <div className="destination-search-result__country-header">{labels.destinationSearchCountryLabel}</div>
      {searchResult.countries.map((country, index) => {
        return <SearchResultItem key={index} item={country} {...otherProps} />;
      })}
    </div>
  );
};

const AreaAndResortCategory = ({ labels, searchResult, ...otherProps }) => {
  if (searchResult.areaResorts.length < 1) {
    return null;
  }

  return (
    <div className="destination-search-result__resorts">
      <div className="destination-search-result__resort-header">{labels.destinationSearchResortLabel}</div>
      {searchResult.areaResorts.map((resort, index) => {
        return <SearchResultItem key={index} item={resort} {...otherProps} />;
      })}
    </div>
  );
};

export default class DestinationSearchResult extends Component {
  constructor() {
    super();
    this.hideIfClickedOutside = this.hideIfClickedOutside.bind(this);
  }

  componentDidMount() {
    this.addClickOutsideListener();
  }

  componentWillUnmount() {
    this.removeClickOutsideListener();
  }

  addClickOutsideListener() {
    if (isLargeScreen()) {
      document.addEventListener('click', this.hideIfClickedOutside);
    }
  }

  removeClickOutsideListener() {
    if (isLargeScreen()) {
      document.removeEventListener('click', this.hideIfClickedOutside);
    }
  }

  hideIfClickedOutside(event) {
    if (this.searchResultElement && !this.searchResultElement.contains(event.target)) {
      this.props.cancelSearch();
    }
  }

  render() {
    const { searchResult, labels } = this.props;

    if (searchResult.countries.length < 1 && searchResult.areaResorts.length < 1) {
      return (
        <div className="destination-search-result" ref={element => (this.searchResultElement = element)}>
          <div className="destination-search-result__no-result-message">{labels.noSearchResultMessage}</div>
        </div>
      );
    }

    return (
      <div className="destination-search-result" ref={element => (this.searchResultElement = element)}>
        <CountryCategory {...this.props} />
        <AreaAndResortCategory {...this.props} />
      </div>
    );
  }
}

DestinationSearchResult.propTypes = {
  searchResult: PropTypes.object,
  selectDestination: PropTypes.func.isRequired,
  searchString: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  cancelSearch: PropTypes.func,
};
