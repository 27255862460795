import PropTypes from 'prop-types';
import FormField from '../formField/FormField';
import FormFieldLoader from '../formField/FormFieldLoader';

const getCountryName = (countryList, countryId) => {
  const destinationCountry = countryList.find(
    (country) => country.itemId === countryId,
  );
  return destinationCountry?.name;
};

const DestinationField = ({
  labels,
  isLoading,
  isActive,
  isOtherFieldActive,
  toggleOverlay,
  selectedDestination,
  countryList,
  children,
}) => {
  if (isLoading) {
    return (
      <div className="bs-form__select bs-form__destination-select">
        <FormFieldLoader label={labels.destinationInputLabel} />
      </div>
    );
  }

  let destinationName;
  const countryName = countryList
    ? getCountryName(countryList, selectedDestination.countryId)
    : '';

  if (selectedDestination.itemId === -1 && selectedDestination.countryId) {
    destinationName = countryName;
  } else if (selectedDestination.name !== countryName) {
    const countryNameFormated = countryName ? `, ${countryName}` : '';
    destinationName = selectedDestination.name + countryNameFormated;
  } else {
    destinationName = selectedDestination.name;
  }

  return (
    <div
      data-wdio-is-independent={selectedDestination.isIndependent}
      data-wdio-is-charter={selectedDestination.isCharter}
      className="bs-form__select bs-form__destination-select"
    >
      <FormField
        value={destinationName}
        label={labels.destinationInputLabel}
        click={() => toggleOverlay()}
        isActive={isActive}
        isOtherFieldActive={isOtherFieldActive}
      />

      {isActive && children}
    </div>
  );
};

DestinationField.propTypes = {
  children: PropTypes.node,
  labels: PropTypes.object,
  isLoading: PropTypes.bool,
  isActive: PropTypes.bool,
  isOtherFieldActive: PropTypes.bool,
  toggleOverlay: PropTypes.func,
  selectedDestination: PropTypes.object,
  countryList: PropTypes.array,
  setDestination: PropTypes.func,
  searchResult: PropTypes.object,
  searchDestinations: PropTypes.func,
};

export default DestinationField;
