import moment from 'moment';
import { getQueryKeyValue } from '../utils/url/queryHelper';
import QueryKeys from '../utils/url/queryKeys';
import { getApiUrls, getSearchUrlAndStoreSearch } from './api';
import { fetchJson } from '../utils/fetch';

export const getInitState = async (preSelectedSettings) => {
  const { BOOKING_START } = QueryKeys;
  const roomDistribution = getQueryKeyValue(BOOKING_START.roomDistribution);
  const roomAges = typeof roomDistribution === 'string' && roomDistribution.startsWith('|') ? roomDistribution.substr(1) : roomDistribution;
  const categoryId = getQueryKeyValue(BOOKING_START.categoryId);
  const searchCharter = categoryId === '1' || categoryId === '2' || categoryId === '4';

  const apiUrls = await getApiUrls();

  const url = apiUrls.flightOnlyInitStateUrl
    .replace('{departureId}', getQueryKeyValue(BOOKING_START.departureId) || preSelectedSettings.departureId || '-')
    .replace('{countryId}', getQueryKeyValue(BOOKING_START.countryId) || preSelectedSettings.countryId || '-')
    .replace('{destinationId}', getQueryKeyValue(BOOKING_START.destinationId) || preSelectedSettings.resortId || '-')
    .replace('{duration}', getQueryKeyValue(BOOKING_START.duration) || '-')
    .replace('{departureDate}', getQueryKeyValue(BOOKING_START.departureDate) || '-')
    .replace('{returnDate}', getQueryKeyValue(BOOKING_START.returnDate) || '-')
    .replace('{checkInDate}', getQueryKeyValue(BOOKING_START.checkInDate) || '-')
    .replace('{checkOutDate}', getQueryKeyValue(BOOKING_START.checkOutDate) || '-')
    .replace('{roomAges}', roomAges || '-')
    .replace('{searchCharter}', searchCharter || '-');

  const initState = await fetchJson(url);

  return initState;
};

export const searchDestinations = async (departureId, searchTerm) => {
  const trimmedSearchTerm = searchTerm.replace(new RegExp('[0-9/]', 'g'), '').trim();
  const theSearchTerm = (trimmedSearchTerm === '') ? '-1' : trimmedSearchTerm;

  const apiUrls = await getApiUrls();

  const url = apiUrls.flightOnlySearchDestinationsUrl
    .replace('{departureId}', departureId)
    .replace('{searchTerm}', theSearchTerm);

  const destinations = await fetchJson(url);

  return destinations;
};

export const getCountries = async (departureAirportId) => {
  const apiUrls = await getApiUrls();

  const url = apiUrls.flightOnlyCountriesUrl
    .replace('{departureId}', departureAirportId);


  const countries = await fetchJson(url);

  return countries;
};

export const getResorts = async (departureAirportId, countryId) => {
  const apiUrls = await getApiUrls();

  const url = apiUrls.flightOnlyDestinationAirportsUrl
    .replace('{departureId}', departureAirportId)
    .replace('{countryId}', countryId);

  const resorts = await fetchJson(url);

  return resorts;
};

export const getDurations = async (departureAirportId, countryId, resortId) => {
  const apiUrls = await getApiUrls();

  const url = apiUrls.flightOnlyItemDurationsUrl
    .replace('{departureId}', departureAirportId)
    .replace('{countryId}', countryId)
    .replace('{itemId}', resortId);

  const durations = await fetchJson(url);

  return durations;
};

export const getCharterDepartureDates = async (departureAirportId, countryId, resortId, duration) => {
  const apiUrls = await getApiUrls();

  const url = apiUrls.flightOnlyCharterDepartureDatesUrl
    .replace('{departureId}', departureAirportId)
    .replace('{countryId}', countryId)
    .replace('{itemId}', resortId)
    .replace('{duration}', duration);

  const departureDates = fetchJson(url);

  return departureDates;
};

export const getIndependentDepartureDates = async (departureAirportId, countryId, resortId, duration, flexible) => {
  const apiUrls = await getApiUrls();

  const url = apiUrls.flightOnlyIndependentDepartureDatesUrl
    .replace('{departureId}', departureAirportId)
    .replace('{countryId}', countryId)
    .replace('{itemId}', resortId)
    .replace('{duration}', duration)
    .replace('{flexible}', flexible);

  const departureDates = await fetchJson(url);

  return departureDates;
};

export const getExtendedSearchOptions = async (baseUrl, siteId, departureAirportId, destinationAirportId) => {
  const url = `${baseUrl}/independentweb-extended-search/options/${siteId}?departureId=${departureAirportId}&destinationAirportId=${destinationAirportId}`;
  const extendedSearchOptions = await fetchJson(url);

  return {
    ...extendedSearchOptions,
    selectedCabinClass: extendedSearchOptions.displayCabinClasses?.includes('Economy')
      ? 'Economy'
      : null,
  };
};

export const getSearchUrl = async (flightSection) => {
  const {
    searchDirectOnly,
    searchLuggageIncluded,
    selectedCabinClass,
    selectedDepartureAirport,
    selectedDepartureDate,
    selectedDestination,
    selectedDuration,
    selectedPassengerCombination,
    selectedReturnDate,
  } = flightSection;

  const getAges = () => {
    const isCharter = selectedDuration.bookingType === 'charter';
    let ages = isCharter ? '|' : '';
    const roomDivider = isCharter ? '' : '|';
    const adults = selectedPassengerCombination.numberOfAdults;
    const children = selectedPassengerCombination.numberOfChildren;
    if (adults > 0 || children > 0) {
      ages += roomDivider;
      for (let y = 0; y < adults; y += 1) {
        ages += '42,';
      }
      for (let y = 0; y < children; y += 1) {
        ages += `${selectedPassengerCombination.childAges[y]},`;
      }
    }
    ages = ages.slice(0, ages.length - 1);
    return ages;
  };

  const getChildrenAges = () => {
    let childrenAges = '';
    const { numberOfChildren } = selectedPassengerCombination;
    if (numberOfChildren > 0) {
      childrenAges = selectedPassengerCombination.childAges.join(',');
    }
    return childrenAges;
  };

  const depDate = moment(selectedDepartureDate).format('YYYYMMDD');
  const retDate = moment(selectedReturnDate).format('YYYYMMDD');
  let duration = selectedDuration.defaultDuration;

  if (selectedDuration.flexibleDuration) {
    duration = '-1';
  }

  const depId = selectedDepartureAirport.itemId;
  const ctryId = selectedDestination.countryId || selectedDestination.itemId;
  let destId = selectedDestination.itemId;
  if (selectedDestination.itemId === selectedDestination.countryId) {
    destId = '-1';
  }
  const roomAges = getAges();
  const childrenAges = getChildrenAges();
  const adults = selectedPassengerCombination.numberOfAdults;
  let searchCharter = 'false';
  let categoryId = '5';
  if (selectedDuration.bookingType === 'charter') {
    searchCharter = 'true';
    categoryId = '4';
  }

  const sectionData = {
    depId,
    destId,
    ctryId,
    areaId: '-',
    resId: '-',
    dur: duration,
    depDate,
    retDate,
    depMonths: '0',
    roomAges,
    sameRoom: '-',
    searchCharter,
    categoryId,
    categoryFilterId: '-',
  };

  const searchUrls = await getSearchUrlAndStoreSearch(sectionData);

  let url;
  if (selectedDuration.bookingType === 'independent') {
    url = searchUrls.flightIndependent;
  } else if (selectedDuration.bookingType === 'charter') {
    url = searchUrls.flightCharter;
  }

  url = url
    .replace('{depId}', depId)
    .replace('{ctryId}', ctryId)
    .replace('{destId}', destId)
    .replace('{depDate}', depDate)
    .replace('{duration}', duration)
    .replace('{retDate}', retDate)
    .replace('{roomAges}', roomAges)
    .replace('{adults}', adults)
    .replace('{childrenAges}', childrenAges);

  url +=
    `${searchDirectOnly ? '&DirectFlight=true' : ''}` +
    `${searchLuggageIncluded ? '&IncludeBaggage=true' : ''}` +
    `${selectedCabinClass ? `&CabinClass=${selectedCabinClass}` : ''}`;

  return url;
};
