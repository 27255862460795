import cx from 'classnames';
import PropTypes from 'prop-types';
import { Checkbox } from '@bwoty-web/ui-kit';

import './priceCalendarControls.scss';

const PriceCalendarControls = ({
  changeDuration,
  changeDurationForPriceCalendar,
  labels,
  previousDuration,
  priceCalendarDurationOptions,
  renderDirectFlightDescription,
  selectedDuration,
  setSearchDirectOnly,
  setShowDirectFlights,
  setShowPriceCalendar,
  showDirectFlights,
  showPriceCalendar,
}) => {
  const { hasCalendar, hasDirectFlights, flexibleDuration } = selectedDuration;

  const setShowCalendarAndChangeDuration = (newDuration) => {
    setShowPriceCalendar(true);
    changeDurationForPriceCalendar(newDuration);
  };

  // If flexible duration
  // Show link to change duration to the first calendar duration
  if (flexibleDuration && priceCalendarDurationOptions && priceCalendarDurationOptions.length > 0) {
    const newDuration = priceCalendarDurationOptions[0];

    return (
      <div className="bsh-price-calendar__duration-text">
        <span>{labels.priceCalendarExists.replace('{duration}', newDuration.name)} </span>
        <button
          className="bsh-price-calendar__change-duration-btn"
          type="button"
          onClick={() => setShowCalendarAndChangeDuration(newDuration)}
        >
          {labels.priceCalendarChangeDurationLink}
        </button>
      </div>
    );
  }

  if (hasCalendar) {
    return (
      <div className="bsh-price-calendar-container">
        <div className="bsh-price-calendar__top-container">
          {previousDuration ? (
            <button
              type="button"
              className="bsh-price-calendar__change-back-duration-link"
              onClick={() => {
                setShowPriceCalendar(false);
                changeDuration(previousDuration);
              }}
            >
              {labels.hidePricesAndChangeBackDuration}
            </button>
          ) : (
            <Checkbox
              checked={showPriceCalendar}
              onChange={() => setShowPriceCalendar(!showPriceCalendar)}
              uniqueId="price-calendar-checkbox"
            >
              {labels.priceCalendar}
            </Checkbox>
          )}
          {showPriceCalendar && (
            <span className="bsh-price-calendar__price-description-mobile">
              {labels.priceDescriptionMobile}
            </span>
          )}
        </div>
        {showPriceCalendar && hasDirectFlights && (
          <div className="bsh-price-calendar__direct-flight-toggle">
            <button
              type="button"
              className={cx('bsh-price-calendar__show-all', {
                selected: !showDirectFlights,
              })}
              onClick={() => {
                setShowDirectFlights(false);
                setSearchDirectOnly(false);
              }}
            >
              {labels.showAllFlights}
            </button>
            <button
              type="button"
              className={cx('bsh-price-calendar__show-direct-flights', {
                selected: showDirectFlights,
              })}
              onClick={() => {
                setShowDirectFlights(true);
                setSearchDirectOnly(true);
              }}
            >
              {labels.showOnlyDirectFlights}
            </button>
          </div>
        )}
        {renderDirectFlightDescription(true)}
      </div>
    );
  }

  return null;
};

PriceCalendarControls.propTypes = {
  changeDuration: PropTypes.func.isRequired,
  changeDurationForPriceCalendar: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    hidePricesAndChangeBackDuration: PropTypes.string,
    priceCalendar: PropTypes.string,
    priceCalendarChangeDurationLink: PropTypes.string,
    priceCalendarExists: PropTypes.string,
    priceDescriptionMobile: PropTypes.string,
    showAllFlights: PropTypes.string,
    showOnlyDirectFlights: PropTypes.string,
  }),
  previousDuration: PropTypes.shape({}),
  priceCalendarDurationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ).isRequired,
  renderDirectFlightDescription: PropTypes.func.isRequired,
  selectedDuration: PropTypes.shape({
    flexibleDuration: PropTypes.bool,
    hasCalendar: PropTypes.bool,
    hasDirectFlights: PropTypes.bool,
  }).isRequired,
  setSearchDirectOnly: PropTypes.func.isRequired,
  setShowDirectFlights: PropTypes.func.isRequired,
  setShowPriceCalendar: PropTypes.func.isRequired,
  showDirectFlights: PropTypes.bool,
  showPriceCalendar: PropTypes.bool,
};

PriceCalendarControls.defaultProps = {
  labels: {},
  previousDuration: null,
  showDirectFlights: false,
  showPriceCalendar: false,
};

export default PriceCalendarControls;
