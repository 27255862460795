import cx from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Icon } from '@bwoty-web/ui-kit';
import { UI_ARROW_RIGHT, UI_CHEVRON_UP, UI_CHEVRON_DOWN } from '@bwoty-web/ui-kit/icons';

const capitalize = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

class FormFieldDate extends Component {
  constructor() {
    super();
  }

  toggle(event) {
    const { click } = this.props;
    click(event);
  }

  render() {
    const {
      className,
      dateSelectionInProgress,
      departureDate,
      departureDateSelected,
      hideReturnDate,
      id,
      isActive,
      isOtherFieldActive,
      label,
      returnDate,
      selectedMonths,
    } = this.props;

    const renderValue = () => {
      const departureDateClasses = cx('form-field__departure', {
        'form-field__departure--active': dateSelectionInProgress && !departureDateSelected,
        'form-field__departure--no-return-date': hideReturnDate,
      });

      const returnDateClasses = cx('form-field__return', {
        'form-field__return--active': dateSelectionInProgress && departureDateSelected,
        'form-field__return--disabled': hideReturnDate,
      });

      return (
        <div>
          <div className="form-field__value">
            <div className={departureDateClasses}>
              <span>{departureDate}</span>
              {!hideReturnDate && (
                <Icon className="form-field__date-separator" path={UI_ARROW_RIGHT} size="xxxs" />
              )}
            </div>
            <div className={returnDateClasses}>
              <span>{returnDate}</span>
            </div>
          </div>
          <input type="hidden" value={`${departureDate} ${returnDate}`} id={id} />
        </div>
      );
    };

    const renderMonthValue = () => {
      const monthsString = selectedMonths
        .map((date) => {
          const { dateSettings } = this.props;
          const month = dateSettings.months[date.month()];
          return `${capitalize(month)} ${date.year()}`;
        })
        .join(', ');

      return (
        <div>
          <div className="form-field__value">
            <span>{monthsString}</span>
          </div>
          <input type="hidden" value={monthsString} id={id} />
        </div>
      );
    };

    const formFieldClasses = cx(className, {
      'form-field': true,
      'form-field--date': true,
      'form-field--active': isActive,
      'form-field--other-active': isOtherFieldActive,
    });

    return (
      <button className={formFieldClasses} onClick={(event) => this.toggle(event)} type="button">
        <Icon
          className="form-field__arrow"
          path={isActive ? UI_CHEVRON_UP : UI_CHEVRON_DOWN}
          size="xxxs"
        />
        <label className="form-field__label">{label}</label>
        {selectedMonths && selectedMonths.length > 0 ? renderMonthValue() : renderValue()}
      </button>
    );
  }
}

FormFieldDate.propTypes = {
  className: PropTypes.string,
  click: PropTypes.func.isRequired,
  dateSelectionInProgress: PropTypes.bool,
  dateSettings: PropTypes.shape({
    months: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  departureDate: PropTypes.string.isRequired,
  departureDateSelected: PropTypes.bool.isRequired,
  hideReturnDate: PropTypes.bool,
  id: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  isOtherFieldActive: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  returnDate: PropTypes.string.isRequired,
  selectedMonths: PropTypes.arrayOf(PropTypes.shape()),
};

FormFieldDate.defaultProps = {
  className: undefined,
  dateSelectionInProgress: undefined,
  hideReturnDate: undefined,
  id: undefined,
  selectedMonths: undefined,
};

export default FormFieldDate;
