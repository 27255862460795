import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Stepper, Button, Select, Checkbox, RadioButton, Link } from '@bwoty-web/ui-kit';
import { useIsGlobetrotter, useIsMobile } from '@bwoty-web/ui-kit/hooks';
import SelectOverlay from '../selectOverlay/SelectOverlay';
import { maxNumberOfRooms, childAgeMax } from '../../constants/pax';
import {
  getTotalNumberOfAdults,
  getTotalNumberOfChildren,
  getTotalNumberOfPax,
  getNumberOfPaxAvailable,
} from '../../utils/pax/helper';
import {
  isValidAge,
  validRoomDistribution,
  validAges,
  validNumberOfInfants,
  validNumberOfAdultsInRoom,
} from '../../utils/pax/validation';

import './paxSelect.scss';

const emptyRoom = {
  numberOfAdults: 0,
  numberOfChildren: 0,
  childAges: [],
};

const PaxSelect = ({
  labels,
  roomDistribution,
  close,
  multipleRooms,
  numberOfRooms,
  maxNumberOfPax,
  showManyPaxLink,
  preferAllPaxInSameRoom,
  changeRoomDistribution,
  activeValidation,
  isCruise,
  hideSameRoomCheckbox,
  showCabinClasses,
  selectedCabinClass,
  setCabinClass,
  isDP,
}) => {
  const isMobile = useIsMobile();

  const {
    paxLayerHeading,
    roomLabel,
    adultPluralText,
    yearText,
    chooseText,
    paxConfirmLabel,
    childSingularText,
    childPluralText,
    addRoomLabel,
    removeRoomLabel,
    everyoneInSameRoom: everyoneInSameRoomLabel,
    infantValidationText,
    invalidChildaAgeMessage,
    cruiseNumberOfPersonLabel,
    cruiseNumberOfPersonPreamble,
    cruiseAgeLabel,
    cruiseAgePreamble,
    adultSelectSubLabel,
    numberOfAdultsInRoomMessage,
    manyPaxLink,
    manyPaxLinkText,
  } = labels;

  const isGlobetrotter = useIsGlobetrotter();

  const getAllChildAges = () => {
    const ages = [];

    roomDistribution.forEach((room) => {
      ages.push(...room.childAges);
    });

    return ages;
  };

  const getInitialRoomDistribution = () => {
    if (multipleRooms) {
      return [...roomDistribution];
    }

    return [
      {
        numberOfAdults: getTotalNumberOfAdults(roomDistribution),
        numberOfChildren: getTotalNumberOfChildren(roomDistribution),
        childAges: getAllChildAges(),
      },
      { ...emptyRoom },
      { ...emptyRoom },
    ];
  };

  const [roomCount, setRoomCount] = useState(numberOfRooms);
  const [rooms, setRooms] = useState(getInitialRoomDistribution());
  const [allPaxInSameRoom, setAllPaxInSameRoom] = useState(preferAllPaxInSameRoom);

  const addRoom = () => {
    if (rooms.length < maxNumberOfRooms) {
      const newRoomDistribution = [...rooms];
      newRoomDistribution.push({ ...emptyRoom });
      setRooms(newRoomDistribution);
    }
    setRoomCount(roomCount + 1);
  };

  const removeRoom = (roomIndex) => {
    const newRoomDistribution = [...rooms];
    newRoomDistribution.splice(roomIndex, 1);
    setRoomCount(roomCount - 1);
    setRooms(newRoomDistribution);
  };

  const changeNumberOfAdults = (count, roomIndex) => {
    const newRoomDistribution = [...rooms];
    newRoomDistribution[roomIndex].numberOfAdults = count;
    setRooms(newRoomDistribution);
  };

  const changeNumberOfChildren = (count, roomIndex) => {
    const newRoomDistribution = [...rooms];
    newRoomDistribution[roomIndex].numberOfChildren = count;
    setRooms(newRoomDistribution);
  };

  const changeChildAge = (age, roomIndex, childIndex) => {
    const newRoomDistribution = [...rooms];
    newRoomDistribution[roomIndex].childAges[childIndex] = age;
    setRooms(newRoomDistribution);
  };

  const getChildAgesList = () => {
    return Array.from({ length: childAgeMax + 1 }, (v, age) => {
      return {
        value: age,
        name: `${age} ${yearText}`,
      };
    });
  };

  const confirm = (e) => {
    const sameRoom = getTotalNumberOfPax(rooms) < 7 ? allPaxInSameRoom : false;
    changeRoomDistribution(e, rooms, roomCount, sameRoom);
  };

  const invalidChildAges = !validAges(rooms);
  const invalidNumberOfInfants = !validNumberOfInfants(rooms);
  const invalidNumberOfAdultsInRoom = !validNumberOfAdultsInRoom(rooms);

  const showAddRoomButton = multipleRooms && roomCount < maxNumberOfRooms;
  const classes = classNames('bs-pax-select', {
    'bs-pax-select--multiple-rooms': multipleRooms,
  });

  return (
    <SelectOverlay
      heading={paxLayerHeading}
      close={() => close()}
      clickedOutside={(event) => confirm(event)}
      footerContent={
        <Button
          variant={isMobile ? 'primary' : 'secondary'}
          disabled={!validRoomDistribution(rooms)}
          onClick={(e) => confirm(e)}
          className="bs-pax-select-confirm-button"
        >
          {paxConfirmLabel}
        </Button>
      }
    >
      <div className={classes}>
        {isGlobetrotter && !isMobile && (
          <h3 className="select-overlay__heading2">{paxLayerHeading}</h3>
        )}
        <div className="bs-pax-select__main-content">
          <div className="bs-pax-select-rooms">
            {[...Array(multipleRooms ? roomCount : 1)].map((a, roomIndex) => {
              const room = rooms[roomIndex];
              const { numberOfAdults, numberOfChildren } = room;

              const adultsMinValue = roomIndex === 0 ? 1 : 0;
              const childrenMinValue = 0;
              const adultsMaxValue =
                getNumberOfPaxAvailable(rooms, maxNumberOfPax) + numberOfAdults;
              const childrenMaxValue =
                getNumberOfPaxAvailable(rooms, maxNumberOfPax) + numberOfChildren;
              const showSameRoomCheckbox =
                !multipleRooms &&
                getTotalNumberOfPax(rooms) > 2 &&
                getTotalNumberOfPax(rooms) < 7 &&
                !hideSameRoomCheckbox;
              const showRemoveButton = roomIndex !== 0;

              return (
                <div
                  className="bs-pax-select-room"
                  // eslint-disable-next-line react/no-array-index-key
                  key={`room-${roomIndex}`}
                >
                  {multipleRooms && (
                    <div className="bs-pax-select-room__top-container">
                      <div className="bs-pax-select-room__label">
                        {`${roomLabel} ${roomIndex + 1}`}
                      </div>
                      {showRemoveButton && (
                        <button
                          className="bs-pax-select-room__remove-button"
                          type="button"
                          onClick={() => removeRoom(roomIndex)}
                        >
                          {removeRoomLabel}
                        </button>
                      )}
                    </div>
                  )}
                  <div className="bs-pax-select-room__steppers">
                    <div className="bs-pax-select-room__stepper-container">
                      <Stepper
                        title={adultPluralText}
                        subtitle={adultSelectSubLabel}
                        minValue={adultsMinValue}
                        maxValue={adultsMaxValue}
                        value={numberOfAdults}
                        onChange={(count) => changeNumberOfAdults(count, roomIndex)}
                      />
                    </div>
                    <div className="bs-pax-select-room__stepper-container">
                      <Stepper
                        title={childPluralText}
                        subtitle={`0-17 ${yearText}`}
                        minValue={childrenMinValue}
                        maxValue={childrenMaxValue}
                        value={numberOfChildren}
                        onChange={(count) => changeNumberOfChildren(count, roomIndex)}
                      />
                    </div>
                  </div>
                  {numberOfChildren > 0 && (
                    <div className="bs-pax-select-room__children-ages">
                      {[...Array(numberOfChildren)].map((b, childIndex) => {
                        const childAge = parseInt(room.childAges[childIndex], 10);
                        const isInvalid = !isValidAge(childAge);
                        const childAgesList = getChildAgesList();
                        const childItemId = `${childSingularText}_${childIndex + 1}`;
                        return (
                          <div className="bs-pax-select-room__child-age" key={childItemId}>
                            <label
                              htmlFor={childItemId}
                              className="bs-pax-select-room__child-age-label"
                            >
                              {`${childSingularText} ${childIndex + 1}`}
                            </label>
                            <Select
                              className="bs-pax-select-room__child-age-select"
                              items={getChildAgesList()}
                              defaultValue={
                                isInvalid ? chooseText || 'Välj' : childAgesList[childAge]
                              }
                              value={childAgesList[childAge]}
                              onChange={(e) =>
                                changeChildAge(e.target.value, roomIndex, childIndex)
                              }
                              invalid={activeValidation && isInvalid}
                              id={childItemId}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {showSameRoomCheckbox && (
                    <Checkbox
                      className="bs-pax-select-room__prefer-all-in-same-room"
                      checked={allPaxInSameRoom}
                      onChange={() => setAllPaxInSameRoom(!allPaxInSameRoom)}
                      uniqueId="same-room"
                    >
                      {everyoneInSameRoomLabel}
                    </Checkbox>
                  )}
                </div>
              );
            })}
          </div>
          {showManyPaxLink && manyPaxLink && manyPaxLinkText && getTotalNumberOfPax(rooms) > 5 && (
            <Link className="bs-pax-select-many-pax-link" href={manyPaxLink}>
              {manyPaxLinkText}
            </Link>
          )}
          {(activeValidation || !validRoomDistribution(rooms)) && (
            <div className="bs-pax-select-validation">
              {invalidChildAges && (
                <p className="bs-pax-select-validation__message">{invalidChildaAgeMessage}</p>
              )}
              {invalidNumberOfInfants && (
                <p className="bs-pax-select-validation__message">{infantValidationText}</p>
              )}
              {invalidNumberOfAdultsInRoom && (
                <p className="bs-pax-select-validation__message">{numberOfAdultsInRoomMessage}</p>
              )}
            </div>
          )}
          {isCruise && (
            <div className="bs-pax-select-cruise-info-container">
              <div className="bs-pax-select-cruise-info">
                <div className="bs-pax-select-cruise-info__header">{cruiseNumberOfPersonLabel}</div>
                <p>{cruiseNumberOfPersonPreamble}</p>
              </div>
              <div className="bs-pax-select-cruise-info">
                <div className="bs-pax-select-cruise-info__header">{cruiseAgeLabel}</div>
                <p>{cruiseAgePreamble}</p>
              </div>
            </div>
          )}
          {showAddRoomButton && isMobile && !isGlobetrotter && (
            <Button className="bs-pax-select-add-room-button" onClick={() => addRoom()}>
              {addRoomLabel}
            </Button>
          )}
          {showAddRoomButton && (!isMobile || isGlobetrotter) && (
            <button
              className="bs-pax-select-add-room-button-desktop"
              onClick={() => addRoom()}
              type="button"
            >
              {addRoomLabel}
            </button>
          )}
        </div>
      </div>
      {!!showCabinClasses?.length && isDP && (
        <div className="bs-pax-select__flight-class">
          <h3 className="bs-pax-select__flight-class-heading select-overlay__heading2">
            {labels.flightClass || 'Flightclass'}
          </h3>
          <div className="bs-pax-select__flight-classes">
            {showCabinClasses.map((name) => (
              <RadioButton
                onChange={() => setCabinClass(name)}
                name="cabinclass"
                checked={name === selectedCabinClass}
                key={name}
                uniqueId={`cabinclass_${name}`}
                style={{ marginBottom: '20px' }}
              >
                {labels[`class${name}`] || name}
              </RadioButton>
            ))}
          </div>
        </div>
      )}
    </SelectOverlay>
  );
};

PaxSelect.propTypes = {
  roomDistribution: PropTypes.arrayOf(
    PropTypes.shape({
      numberOfAdults: PropTypes.number,
      numberOfChildren: PropTypes.number,
      childAges: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  multipleRooms: PropTypes.bool,
  numberOfRooms: PropTypes.number.isRequired,
  maxNumberOfPax: PropTypes.number.isRequired,
  showManyPaxLink: PropTypes.bool,
  preferAllPaxInSameRoom: PropTypes.bool,
  changeRoomDistribution: PropTypes.func.isRequired,
  // setAllPaxInSameRoomFunc: PropTypes.func,
  close: PropTypes.func.isRequired,
  activeValidation: PropTypes.bool,
  isCruise: PropTypes.bool,
  hideSameRoomCheckbox: PropTypes.bool,
  labels: PropTypes.shape({
    paxLayerHeading: PropTypes.string,
    roomLabel: PropTypes.string,
    adultPluralText: PropTypes.string,
    childPluralText: PropTypes.string,
    yearText: PropTypes.string,
    chooseText: PropTypes.string,
    paxConfirmLabel: PropTypes.string,
    childSingularText: PropTypes.string,
    addRoomLabel: PropTypes.string,
    removeRoomLabel: PropTypes.string,
    everyoneInSameRoom: PropTypes.string,
    infantValidationText: PropTypes.string,
    invalidChildaAgeMessage: PropTypes.string,
    cruiseNumberOfPersonLabel: PropTypes.string,
    cruiseNumberOfPersonPreamble: PropTypes.string,
    cruiseAgeLabel: PropTypes.string,
    cruiseAgePreamble: PropTypes.string,
    adultSelectSubLabel: PropTypes.string,
    numberOfAdultsInRoomMessage: PropTypes.string,
    manyPaxLink: PropTypes.string,
    manyPaxLinkText: PropTypes.string,
    flightClass: PropTypes.string,
  }).isRequired,
  showCabinClasses: PropTypes.arrayOf(PropTypes.string),
  selectedCabinClass: PropTypes.string,
  setCabinClass: PropTypes.func,
  isDP: PropTypes.bool,
};

PaxSelect.defaultProps = {
  preferAllPaxInSameRoom: false,
  activeValidation: false,
  isCruise: false,
  multipleRooms: false,
  showManyPaxLink: false,
  hideSameRoomCheckbox: false,
  // setAllPaxInSameRoomFunc: () => {},
  showCabinClasses: null,
  selectedCabinClass: null,
  setCabinClass: () => {},
  isDP: false,
};

export default PaxSelect;
