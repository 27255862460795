import { useEffect } from 'react';

const useCloseOnEscape = (close, ref) => {
  useEffect(() => {
    const contentElement = ref.current;
    const handleEscapeKeyPress = (event) => {
      if (event.key === 'Escape') {
        contentElement.previousElementSibling.focus();
        close();
      }
    };
    contentElement.addEventListener('keydown', handleEscapeKeyPress);
    return () => {
      contentElement.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [close, ref]);
};

export default useCloseOnEscape;
