import cx from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from '@bwoty-web/ui-kit';
import { UI_CHEVRON_DOWN, UI_CHEVRON_UP } from '@bwoty-web/ui-kit/icons';

import './BSButton.scss';

const BSButton = ({ children, expandable, expanded, name, onClick, selected, ...rest }) => {
  const iconPath = expanded ? UI_CHEVRON_UP : UI_CHEVRON_DOWN;

  const destinationClasses = cx('bs-button', {
    'bs-button--selected': !expanded && selected,
    'bs-button--expanded': expanded,
  });

  const btnProps = {};
  if (expandable) btnProps['aria-expanded'] = expanded;

  return (
    <button
      className={destinationClasses}
      name={name || undefined}
      onClick={(e) => onClick(e)}
      type="button"
      {...btnProps}
      {...rest}
    >
      {children}
      {expandable && <Icon path={iconPath} className="bs-button__arrow-icon" size="xxs" />}
    </button>
  );
};

BSButton.propTypes = {
  children: PropTypes.node.isRequired,
  expandable: PropTypes.bool,
  expanded: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

BSButton.defaultProps = {
  expandable: false,
  expanded: false,
  name: '',
  selected: false,
};

export default BSButton;
