import PropTypes from 'prop-types';
import { useState } from 'react';
import { useIsGlobetrotter, useIsMobile } from '@bwoty-web/ui-kit/hooks';

import BSButton from '../bsButton/BSButton';
import DestTypes from '../../constants/destinationTypes';
import DestinationSearch from './DestinationSearch';
import { DestinationSelectKeyDownHandler } from '../../utils/keyboardHandler';

import './destinationSelect.scss';

const NUMBER_OF_COLUMNS = 3;

const DestinationSelect = ({
  countries,
  labels,
  searchDestinations,
  searchResult,
  sectionType,
  selectedDestination: selDest,
  setDestination,
}) => {
  const findAreaResortOrAirportId = (countryId, itemId) => {
    if (!countryId) return null;

    const selectedCountry = countries.find((country) => country.itemId === countryId);

    if (sectionType === 'flight') {
      return selectedCountry.destinationAirports.find((airport) => airport.itemId === itemId)
        ?.itemId;
    }

    const selectedArea = selectedCountry.areaResorts.find(
      (areaOrResort) => areaOrResort.itemId === itemId,
    );

    const selectedResort = selectedCountry.areaResorts.find((areaOrResort) =>
      areaOrResort.resorts.find((resort) => resort.itemId === itemId),
    );

    return selectedArea?.itemId || selectedResort?.itemId;
  };

  const isMobile = useIsMobile();
  const isGlobetrotter = useIsGlobetrotter();
  const [searchString, setSearchString] = useState('');

  // Find selected country from selected destination
  const [expandedCountry, setExpandedCountry] = useState(
    selDest.countryId ? selDest.countryId : selDest.itemId,
  );

  // Find selected area, resort or airport from selected destination
  const [expandedArea, setExpandedArea] = useState(
    findAreaResortOrAirportId(selDest?.countryId, selDest?.itemId),
  );

  const minDestinationsPerCol = Math.floor(countries.length / NUMBER_OF_COLUMNS);
  let rest = countries.length - minDestinationsPerCol * NUMBER_OF_COLUMNS;

  const columns = [];
  let counter = 0;

  for (let i = 0; i < NUMBER_OF_COLUMNS; i += 1) {
    let numberOfItems = minDestinationsPerCol;
    const countryList = countries.slice();

    if (rest > 0) {
      numberOfItems += 1;
      rest -= 1;
    }

    const countriesInColumn = countryList.slice(counter, counter + numberOfItems);
    columns.push({ columnKey: `Column-${i}`, countriesInColumn });

    counter += numberOfItems;
  }

  const renderAreaAndResorts = (country, colIdx) => {
    const areaResorts = country.areaResorts || country.destinationAirports;

    const handleAreaExpand = (areaId) => {
      if (expandedArea === areaId) {
        setExpandedArea(null);
      } else {
        setExpandedArea(areaId);
      }
    };

    return (
      <div>
        {areaResorts.map((areaOrResort) => {
          const { isArea } = areaOrResort;

          if (isArea) {
            const area = areaOrResort;
            const areaExpanded = expandedArea === area.itemId;
            const isAreaSelected =
              area.itemId === (selDest.type === DestTypes.AREA ? selDest?.itemId : selDest?.areaId);

            return (
              <div key={area.itemId}>
                <BSButton
                  data-col-nr={colIdx}
                  expandable
                  expanded={areaExpanded}
                  name={area.name}
                  onClick={() => handleAreaExpand(area.itemId)}
                  selected={isAreaSelected}
                >
                  {area.name}
                </BSButton>
                {areaExpanded && (
                  <div aria-expanded="true" className="destination-select__expanded-countries">
                    {area.resorts.map((resort) => {
                      let isResortSelected =
                        resort.itemId === selDest.itemId && resort.type === selDest.type;
                      if (resort.itemId === -1 && selDest.itemId === area.itemId)
                        isResortSelected = true;
                      return (
                        <BSButton
                          data-col-nr={colIdx}
                          key={resort.itemId}
                          name={resort.name}
                          onClick={(e) => setDestination(e, resort.itemId === -1 ? area : resort)}
                          selected={isResortSelected}
                        >
                          {resort.name}
                        </BSButton>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          }

          const resort = areaOrResort;
          const isResortSelected =
            resort.itemId === selDest.itemId && resort.countryId === selDest.countryId;

          return (
            <BSButton
              data-col-nr={colIdx}
              key={resort.itemId}
              name={resort.name}
              onClick={(e) => setDestination(e, resort)}
              selected={isResortSelected}
            >
              {resort.name}
            </BSButton>
          );
        })}
      </div>
    );
  };

  return (
    <div className="destination-select-container">
      {isGlobetrotter && !isMobile && (
        <h3 className="select-overlay__heading2">{labels.destinationLayerHeading}</h3>
      )}
      <div className="destination-select__search-n-destination">
        <DestinationSearch
          onTextSearch={(searchTerm) => searchDestinations(searchTerm)}
          searchResult={searchResult}
          searchString={searchString}
          setSearchString={setSearchString}
          selectDestination={(e, destination) => setDestination(e, destination)}
          labels={labels}
        />
        {!searchString && (
          <div
            className="destination-select__columns-container"
            onKeyDown={(e) => DestinationSelectKeyDownHandler(e, NUMBER_OF_COLUMNS)}
            role="listbox"
            tabIndex={-1}
          >
            {columns.map(({ columnKey, countriesInColumn }, i) => (
              <ul className="destination-select" data-col-nr={i} key={columnKey}>
                {countriesInColumn.map((country) => {
                  const isCountrySelected =
                    country.itemId ===
                    (selDest?.type === DestTypes.COUNTRY ||
                    selDest?.type === DestTypes.ALL_COUNTRIES
                      ? selDest?.itemId
                      : selDest?.countryId);

                  const areaResorts = country.areaResorts || country.destinationAirports;
                  const hasNoSubAreas =
                    (areaResorts[0].itemId === -1 && country.itemId === -1) ||
                    (country.name === areaResorts[0].name && areaResorts[0].resorts?.length === 0);

                  const countryExpanded = !hasNoSubAreas && expandedCountry === country.itemId;

                  const handleCountryExpand = (countryId) => {
                    if (expandedCountry === countryId) {
                      setExpandedCountry(null);
                    } else {
                      setExpandedCountry(countryId);
                    }
                  };

                  return (
                    <li key={country.itemId}>
                      <BSButton
                        data-col-nr={i}
                        expandable={!hasNoSubAreas}
                        expanded={countryExpanded}
                        name={country.name}
                        onClick={(e) =>
                          hasNoSubAreas
                            ? setDestination(e, areaResorts[0])
                            : handleCountryExpand(country.itemId)
                        }
                        selected={isCountrySelected}
                      >
                        {country.name}
                      </BSButton>
                      {countryExpanded && (
                        <div
                          aria-expanded="true"
                          className="destination-select__expanded-countries"
                        >
                          {renderAreaAndResorts(country, i)}
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

DestinationSelect.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      areaResorts: PropTypes.arrayOf(PropTypes.shape({})),
      departureId: PropTypes.number.isRequired,
      destinationAirports: PropTypes.arrayOf(PropTypes.shape({})),
      isCharter: PropTypes.bool.isRequired,
      isIndependent: PropTypes.bool.isRequired,
      itemId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  labels: PropTypes.shape({
    destinationLayerHeading: PropTypes.string.isRequired,
  }).isRequired,
  searchDestinations: PropTypes.func.isRequired,
  searchResult: PropTypes.shape({}),
  sectionType: PropTypes.oneOf(['package', 'flight']).isRequired,
  selectedDestination: PropTypes.shape({
    areaId: PropTypes.number,
    areaResorts: PropTypes.arrayOf(PropTypes.shape({})),
    countryId: PropTypes.number,
    departureId: PropTypes.number.isRequired,
    isArea: PropTypes.bool,
    isCharter: PropTypes.bool.isRequired,
    isIndependent: PropTypes.bool.isRequired,
    isValidForSearch: PropTypes.bool,
    isValidForSearchIndependent: PropTypes.bool,
    itemId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  setDestination: PropTypes.func.isRequired,
};

DestinationSelect.defaultProps = {
  searchResult: undefined,
};

export default DestinationSelect;
