import PropTypes from 'prop-types';
import { useIsGlobetrotter } from '@bwoty-web/ui-kit/hooks';
import { Icon } from '@bwoty-web/ui-kit';
import { UI_CROSS_CIRCLE, UI_CROSS, UI_SEARCH } from '@bwoty-web/ui-kit/icons';

import './destinationSearchField.scss';

const DestinationSearchField = ({
  onChange,
  cancelSearch,
  searchString,
  labels,
}) => {
  const isGlobetrotter = useIsGlobetrotter();

  const preventEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  return (
    <div className="input__container destination-search-field">
      <Icon
        className="destination-search-field__search-icon"
        path={UI_SEARCH}
        size="xs"
      />
      <input
        type="text"
        className="input"
        placeholder={labels.destinationSearchPlaceholder}
        onInput={(event) => onChange(event.target.value)}
        value={searchString}
        onKeyDown={(event) => preventEnter(event)}
        autoComplete="off"
      />
      {searchString.length > 1 && (
        <button
          className="destination-search-field__delete-button"
          onClick={() => cancelSearch()}
          type="button"
          aria-label={labels.destinationSearchCancelLabel}
        >
          <Icon
            className="destination-search-field__delete-icon"
            path={isGlobetrotter ? UI_CROSS : UI_CROSS_CIRCLE}
            onClick={() => cancelSearch()}
            size={isGlobetrotter ? 'xxs' : 'xs'}
          />
        </button>
      )}
    </div>
  );
};

DestinationSearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  cancelSearch: PropTypes.func.isRequired,
  searchString: PropTypes.string,
  labels: PropTypes.shape({
    destinationSearchPlaceholder: PropTypes.string,
    destinationSearchCancelLabel: PropTypes.string,
  }).isRequired,
};

DestinationSearchField.defaultProps = {
  searchString: '',
};

export default DestinationSearchField;
