import { useEffect } from 'react';

const useOnClickOutside = (ref, onClickOutside, enabled = true) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      if (ref.current.parentElement === event.target.parentElement) {
        event.stopPropagation();
        onClickOutside();
        return;
      }

      onClickOutside();
    };

    if (enabled) {
      document.addEventListener('click', handleClickOutside, true);

      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
    return undefined;
  }, [ref, onClickOutside, enabled]);
};

export default useOnClickOutside;
