import moment from 'moment';
import store from '../../stores/bookingstart';
import * as flightService from '../../../services/flight';
import { ActionTypes } from '../../constants/actionTypes/bookingstart';
import bookingTypes from '../../../constants/bookingTypes';
import {
  getSelectedDuration,
  getSelectedDepartureDate,
  getSelectedFlightOnlyDestination,
  remapCountriesData,
  remapCountries,
} from '../../../utils/actionHelper';

const getExtendedSearchOptions = (selectedValues) => {
  return async (dispatch) => {
    const { selectedDepartureAirport, selectedDestinationAirport } = selectedValues;

    // Get extended search options
    const { siteSettings } = store.getState().bookingstart;
    const { siteId, baseUrl } = siteSettings;
    const departureAirportId = selectedDepartureAirport.itemId;
    const destinationAirportId = selectedDestinationAirport.itemId;

    const options = await flightService.getExtendedSearchOptions(
      baseUrl,
      siteId,
      departureAirportId,
      destinationAirportId,
    );

    dispatch({
      type: ActionTypes.FLIGHT_GET_EXTENDED_SEARCH_OPTIONS_SUCCESS,
      options,
    });
  };
};

export const initFlightSection = (preSelectedSettings) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.FLIGHT_INIT_SECTION,
    });

    let data = await flightService.getInitState(preSelectedSettings);
    data = remapCountriesData(data, 'flight');

    dispatch({
      type: ActionTypes.FLIGHT_INIT_SECTION_SUCCESS,
      data,
    });

    dispatch(
      getExtendedSearchOptions({
        selectedDepartureAirport: data.selectedDeparture,
        selectedDestinationAirport: data.selectedDestinationAirport,
      }),
    );
  };
};

export const getDepartureDates = (selectedValues) => {
  return async (dispatch) => {
    const { siteSettings } = store.getState().bookingstart;
    const { systemDateFormat, dpBookDaysAhead } = siteSettings.datePicker;

    dispatch({
      type: ActionTypes.FLIGHT_GET_DEPARTURE_DATES,
    });

    const {
      selectedDepartureAirport,
      selectedDestinationAirport,
      selectedDuration,
      selectedDepartureDate,
      selectedReturnDate,
    } = selectedValues;

    const { bookingType, defaultDuration, flexibleDuration } = selectedDuration;

    if (bookingType === bookingTypes.charter) {
      const departureDates = await flightService.getCharterDepartureDates(
        selectedDepartureAirport.itemId,
        selectedDestinationAirport.countryId,
        selectedDestinationAirport.itemId,
        defaultDuration,
      );

      const durationLength = defaultDuration !== -1 ? defaultDuration - 1 : 3;
      const departureDate = getSelectedDepartureDate(departureDates, selectedDepartureDate);
      const returnDate = moment(departureDate).add(durationLength, 'days').format(systemDateFormat);

      dispatch({
        type: ActionTypes.FLIGHT_GET_DEPARTURE_DATES_SUCCESS,
        departureDates,
        departureDate,
        returnDate,
      });
    } else {
      const departureDates = await flightService.getIndependentDepartureDates(
        selectedDepartureAirport.itemId,
        selectedDestinationAirport.countryId,
        selectedDestinationAirport.itemId,
        defaultDuration,
        flexibleDuration,
      );

      const firstPossibleBookingDayDp = moment().add(dpBookDaysAhead, 'days');

      let departureDate = moment(selectedDepartureDate);
      let returnDate = moment(selectedReturnDate);

      if (flexibleDuration) {
        if (departureDate.isBefore(firstPossibleBookingDayDp)) {
          departureDate = moment(firstPossibleBookingDayDp).format(systemDateFormat);
          returnDate = moment(departureDate).add(defaultDuration, 'days').format(systemDateFormat);
        }
      } else {
        departureDate = getSelectedDepartureDate(departureDates, selectedDepartureDate);
        returnDate = moment(selectedDepartureDate)
          .add(defaultDuration, 'days')
          .format(systemDateFormat);
      }

      dispatch({
        type: ActionTypes.FLIGHT_GET_DEPARTURE_DATES_SUCCESS,
        departureDates,
        departureDate,
        returnDate,
      });
    }
  };
};

export const getDurations = (selectedValues) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.FLIGHT_GET_DURATIONS,
    });

    const {
      selectedDepartureAirport,
      selectedDestinationAirport: { countryId, itemId },
      selectedDuration,
    } = selectedValues;

    const durations = await flightService.getDurations(
      selectedDepartureAirport.itemId,
      countryId || itemId,
      itemId,
    );

    const duration = getSelectedDuration(durations, selectedDuration);

    dispatch({
      type: ActionTypes.FLIGHT_GET_DURATIONS_SUCCESS,
      durations,
      duration,
    });

    const newSelectedValues = {
      ...selectedValues,
      selectedDuration: duration,
    };

    dispatch(getDepartureDates(newSelectedValues));
    dispatch(getExtendedSearchOptions(selectedValues));
  };
};

export const getCountries = (selectedValues) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.FLIGHT_GET_COUNTRIES,
    });

    const { selectedDepartureAirport, selectedDestinationAirport } = selectedValues;

    let countries = await flightService.getCountries(selectedDepartureAirport.itemId);
    countries = remapCountries(countries, 'flight');
    const destination = getSelectedFlightOnlyDestination(countries, selectedDestinationAirport);

    dispatch({
      type: ActionTypes.FLIGHT_GET_COUNTRIES_SUCCESS,
      countries,
      destination,
    });

    const newSelectedValues = {
      ...selectedValues,
      countries,
      selectedDestinationAirport: destination,
    };

    dispatch(getDurations(newSelectedValues));
  };
};

export const changeDepartureAirport = (airport) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.FLIGHT_CHANGE_DEPARTURE_AIRPORT,
      airport,
    });

    const selectedValues = {
      ...store.getState().flight,
      selectedDepartureAirport: airport,
    };

    dispatch(getCountries(selectedValues));
  };
};

export const searchDestinations = (itemId, searchTerm) => {
  return async (dispatch) => {
    const searchResults = await flightService.searchDestinations(itemId, searchTerm);
    dispatch({
      type: ActionTypes.FLIGHT_SEARCH_DESTINATIONS_SUCCESS,
      searchResults,
    });
  };
};

export const changeDestination = (destination) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.FLIGHT_CHANGE_DESTINATION,
      destination,
    });

    const selectedValues = {
      ...store.getState().flight,
      selectedDestinationAirport: destination,
    };

    dispatch(getDurations(selectedValues));
  };
};

export const changeDuration = (duration) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.FLIGHT_CHANGE_DURATION,
      duration,
    });

    const selectedValues = {
      ...store.getState().flight,
      selectedDuration: duration,
    };

    dispatch(getDepartureDates(selectedValues));
  };
};

export function changeDepartureDate(date) {
  return (dispatch) => {
    const { siteSettings } = store.getState().bookingstart;
    const { systemDateFormat } = siteSettings.datePicker;
    const { selectedDuration, selectedReturnDate } = store.getState().flight;
    const { defaultDuration, bookingType } = selectedDuration;

    const durationLength = defaultDuration !== -1 ? defaultDuration : 3;
    let returnDate = selectedReturnDate;

    if (bookingType === bookingTypes.charter) {
      returnDate = moment(date)
        .add(durationLength - 1, 'days')
        .format(systemDateFormat);
    } else {
      returnDate = moment(date).add(durationLength, 'days').format(systemDateFormat);
    }

    dispatch({
      type: ActionTypes.FLIGHT_CHANGE_DEPARTURE_DATE,
      date,
      returnDate,
    });
  };
}

export function changeDates(departureDate, returnDate) {
  return {
    type: ActionTypes.FLIGHT_CHANGE_DATES,
    departureDate,
    returnDate,
  };
}

export function changePassengerCombination(numberOfAdults, numberOfChildren, childAges) {
  return {
    type: ActionTypes.FLIGHT_CHANGE_PASSENGER_COMBINATION,
    nOfAdults: numberOfAdults,
    nOfChildren: numberOfChildren,
    childAges,
  };
}

export const setSearchDirectOnly = (searchDirectOnly) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.FLIGHT_SET_SEARCH_DIRECT_ONLY,
      searchDirectOnly,
    });
  };
};

export const setCabinClass = (cabinclass) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.FLIGHT_SET_CABINCLASS,
      cabinclass,
    });
  };
};

export const setSearchLuggageIncluded = (searchLuggageIncluded) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.FLIGHT_SET_SEARCH_LUGGAGE_INCLUDED,
      searchLuggageIncluded,
    });
  };
};
