const DESTINATION_TYPES = {
  ALL_COUNTRIES: 'All Countries',
  COUNTRY: 'Country',
  ALL_AREAS: 'All Areas',
  AREA: 'Area',
  ALL_RESORTS: 'All Resorts',
  RESORT: 'Resort',
  ALL_AIRPORTS: 'All Airports',
  AIRPORT: 'Airport',
};

export default DESTINATION_TYPES;
