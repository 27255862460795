import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button } from '@bwoty-web/ui-kit';
import { connect } from 'react-redux';
import { Component } from 'react';

import bookingTypes from '../constants/bookingTypes';
import DateField from '../components/date/DateField';
import DatePicker from '../components/date/DatePicker';
import DepartureField from '../components/departure/DepartureField';
import DepartureSelect from '../components/departure/DepartureSelect';
import DestinationField from '../components/destination/DestinationField';
import DestinationSelect from '../components/destination/DestinationSelect';
import DurationField from '../components/duration/DurationField';
import DurationSelect from '../components/duration/DurationSelect';
import PaxField from '../components/pax/PaxField';
import PaxSelect from '../components/pax/PaxSelect';
import SelectOverlay from '../components/selectOverlay/SelectOverlay';

import { maxNumberOfPax } from '../constants/pax';
import { validRoomDistribution } from '../utils/pax/validation';
import { shiftFocusToPreviousSiblingIfKeyBoardWasUsed } from '../utils/keyboardHandler';
import { ScrollBsIntoViewIfNeeded, ScrollElementToBottomOfScreen } from '../utils/scroll';

import * as flightService from '../services/flight';
import * as FlightActions from '../redux/actions/bookingstart/flight';
import * as BookingstartActions from '../redux/actions/bookingstart/bookingstart';

import './flightSection.scss';

class FlightSection extends Component {
  constructor(props) {
    super();

    this.state = {
      showDepartureLayer: false,
      showDurationLayer: false,
      showDateLayer: false,
      showDestinationLayer: false,
      showPaxLayer: false,
      showDurationField: this.showDurationField(props.durationList),
      dateSelectionInProgress: false,
      departureDateDuringSelection: null,
      returnDateDuringSelection: null,
      paxIsValid: true,
    };

    this.independentRange = {
      min: moment().add(props.siteSettings.datePicker.dpBookDaysAhead, 'days'),
      max: moment().add(props.siteSettings.datePicker.dpMaxDaysToReturn, 'days'),
    };

    this.charterRange = {
      min: moment(),
      max: moment().add(19, 'month').endOf('month'),
    };

    this.setDate = this.setDate.bind(this);
    this.toggleDateLayer = this.toggleDateLayer.bind(this);
    this.setDateSelectionInProgress = this.setDateSelectionInProgress.bind(this);
    this.setDepartureDateDuringSelection = this.setDepartureDateDuringSelection.bind(this);
    this.setReturnDateDuringSelection = this.setReturnDateDuringSelection.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      showDurationField: this.showDurationField(props.durationList),
    });
  }

  getDateRange(bookingType) {
    return bookingType == 'independent' ? this.independentRange : this.charterRange;
  }

  setDepartureAirport(e, airport) {
    const { dispatch } = this.props;
    dispatch(FlightActions.changeDepartureAirport(airport));
    shiftFocusToPreviousSiblingIfKeyBoardWasUsed(e);
    this.toggleDepartureLayer();
  }

  setDestination(e, destination) {
    const { dispatch } = this.props;
    dispatch(FlightActions.changeDestination(destination));
    shiftFocusToPreviousSiblingIfKeyBoardWasUsed(e);
    this.toggleDestinationLayer();
    ScrollBsIntoViewIfNeeded();
  }

  setDuration(e, duration) {
    const { dispatch } = this.props;
    dispatch(FlightActions.changeDuration(duration));
    shiftFocusToPreviousSiblingIfKeyBoardWasUsed(e);
    this.toggleDurationLayer();
  }

  setDate(departureDate, returnDate) {
    const { dispatch } = this.props;
    if (returnDate != null) {
      dispatch(FlightActions.changeDates(departureDate, returnDate));
    } else {
      dispatch(FlightActions.changeDepartureDate(departureDate));
    }
  }

  setDepartureDateDuringSelection(departureDate) {
    this.setState({ departureDateDuringSelection: departureDate });
  }

  setReturnDateDuringSelection(returnDate) {
    this.setState({ returnDateDuringSelection: returnDate });
  }

  setDateSelectionInProgress(dateSelectionInProgress) {
    this.setState({ dateSelectionInProgress });
  }

  setPassengerCombination(e, roomDistribution) {
    const { dispatch } = this.props;
    dispatch(
      FlightActions.changePassengerCombination(
        roomDistribution[0].numberOfAdults,
        roomDistribution[0].numberOfChildren,
        roomDistribution[0].childAges,
      ),
    );
    shiftFocusToPreviousSiblingIfKeyBoardWasUsed(e);
    this.togglePaxLayer();
  }

  searchDestinations(searchTerm) {
    const { dispatch, selectedDepartureAirport } = this.props;
    dispatch(FlightActions.searchDestinations(selectedDepartureAirport.itemId, searchTerm));
  }

  showDurationField(durationList) {
    if (!durationList) {
      return true;
    }

    const numberOfDurationTypes = durationList.length;
    return (
      numberOfDurationTypes > 1 ||
      (numberOfDurationTypes === 1 && !durationList[0].flexibleDuration)
    );
  }

  isSelectOverlayOpen() {
    const {
      showDateLayer,
      showDepartureLayer,
      showDestinationLayer,
      showDurationLayer,
      showPaxLayer,
    } = this.state;
    return (
      showDepartureLayer ||
      showDurationLayer ||
      showDateLayer ||
      showDestinationLayer ||
      showPaxLayer
    );
  }

  toggleDepartureLayer() {
    this.setState((prevState) => ({ showDepartureLayer: !prevState.showDepartureLayer }));
  }

  toggleDestinationLayer() {
    this.setState((prevState) => ({ showDestinationLayer: !prevState.showDestinationLayer }));
  }

  toggleDurationLayer() {
    this.setState((prevState) => ({ showDurationLayer: !prevState.showDurationLayer }));
  }

  toggleDateLayer() {
    const { returnDateDuringSelection, showDateLayer } = this.state;
    if (showDateLayer && returnDateDuringSelection == null) {
      this.setDateSelectionInProgress(false);
      this.setDepartureDateDuringSelection(null);
    }
    this.setState((prevState) => ({ showDateLayer: !prevState.showDateLayer }));
  }

  togglePaxLayer(paxIsValid = true) {
    this.setState((prevState) => ({
      showPaxLayer: !prevState.showPaxLayer,
      paxIsValid,
    }));
    if (window.innerWidth < 768) {
      ScrollElementToBottomOfScreen(document.getElementsByClassName('bs-form__search-button')[0]);
    }
  }

  navigateToResultPage() {
    const { dispatch } = this.props;
    dispatch(BookingstartActions.searchInit());

    flightService.getSearchUrl(this.props).then((url) => {
      window.location.href = url;
    });
  }

  searchFlight() {
    const { showPaxLayer } = this.state;
    const { selectedPassengerCombination } = this.props;
    if (showPaxLayer) {
      // Wait to the pax layer is closed
      setTimeout(() => {
        if (validRoomDistribution([selectedPassengerCombination])) {
          this.navigateToResultPage();
        } else {
          this.togglePaxLayer(false);
        }
      }, 10);
    } else if (validRoomDistribution([selectedPassengerCombination])) {
      this.navigateToResultPage();
    } else {
      this.togglePaxLayer(false);
    }
  }

  renderOneWayFlightLink() {
    const { isLoading, labels, oneWaySearchUrl } = this.props;
    if (isLoading) return null;
    return (
      <div className="bs-form--flight__one-way-flight-link">
        <a href={oneWaySearchUrl}>{labels.oneWayFlightSearchText}</a>
      </div>
    );
  }

  render() {
    const {
      countryList,
      departureAirportList,
      departureDates,
      dispatch,
      durationList,
      isLoading,
      isMobile,
      labels,
      searchDirectOnly,
      searchLuggageIncluded,
      searchResultsDestination,
      selectedCabinClass,
      selectedDepartureAirport,
      selectedDepartureDate,
      selectedDestination,
      selectedDuration,
      selectedPassengerCombination,
      selectedReturnDate,
      showCabinClasses,
      showDirectOnly,
      showLuggageIncluded,
      siteSettings,
    } = this.props;

    const {
      dateSelectionInProgress,
      departureDateDuringSelection,
      paxIsValid,
      returnDateDuringSelection,
      showDateLayer,
      showDepartureLayer,
      showDestinationLayer,
      showDurationField,
      showDurationLayer,
      showPaxLayer,
    } = this.state;

    const flightSectionClasses = cx('bs-form', 'bs-form--flight', {
      'bs-form--no-duration-field': !showDurationField,
    });

    return (
      <div className={flightSectionClasses}>
        <DepartureField
          isActive={showDepartureLayer}
          isLoading={isLoading}
          isOtherFieldActive={!showDepartureLayer && this.isSelectOverlayOpen()}
          labels={labels}
          selectedAirport={selectedDepartureAirport}
          toggleOverlay={() => this.toggleDepartureLayer()}
        >
          {!isLoading && (
            <SelectOverlay
              close={() => this.toggleDepartureLayer()}
              heading={labels.departureLayerHeading}
            >
              <DepartureSelect
                airportList={departureAirportList}
                changeAirport={(e, airport) => this.setDepartureAirport(e, airport)}
                heading={labels.departureLayerHeading}
                selectedAirport={selectedDepartureAirport}
              />
            </SelectOverlay>
          )}
        </DepartureField>

        <DestinationField
          countryList={countryList}
          isActive={showDestinationLayer}
          isLoading={isLoading}
          isOtherFieldActive={!showDestinationLayer && this.isSelectOverlayOpen()}
          labels={labels}
          selectedDestination={selectedDestination}
          toggleOverlay={() => this.toggleDestinationLayer()}
        >
          {!isLoading && (
            <SelectOverlay
              close={() => this.toggleDestinationLayer()}
              heading={labels.destinationLayerHeading}
            >
              <DestinationSelect
                countries={countryList}
                labels={labels}
                searchDestinations={(searchString) => this.searchDestinations(searchString)}
                searchResult={searchResultsDestination}
                sectionType="flight"
                selectedDestination={selectedDestination}
                setDestination={(e, destination) => this.setDestination(e, destination)}
              />
            </SelectOverlay>
          )}
        </DestinationField>

        <DurationField
          isActive={showDurationLayer}
          isLoading={isLoading}
          isOtherFieldActive={!showDurationLayer && this.isSelectOverlayOpen()}
          labels={labels}
          selectedDuration={selectedDuration}
          showDurationField={showDurationField}
          toggleOverlay={() => this.toggleDurationLayer()}
        >
          {!isLoading && (
            <SelectOverlay
              close={() => this.toggleDurationLayer()}
              heading={labels.durationLayerHeading}
            >
              <DurationSelect
                changeDuration={(e, duration) => this.setDuration(e, duration)}
                durationList={durationList}
                isFlightDuration
                labels={labels}
                selectedDuration={selectedDuration}
              />
            </SelectOverlay>
          )}
        </DurationField>

        <DateField
          datePickerIsActive={showDateLayer}
          dateSelectionInProgress={dateSelectionInProgress}
          dateSettings={siteSettings.datePicker}
          departureDateDuringSelection={departureDateDuringSelection}
          isActive={showDateLayer}
          isHotelOnly={false}
          isLoading={isLoading}
          isOtherFieldActive={!showDateLayer && this.isSelectOverlayOpen()}
          labels={labels}
          returnDateDuringSelection={returnDateDuringSelection}
          selectedDepartureDate={selectedDepartureDate}
          selectedDuration={selectedDuration}
          selectedReturnDate={selectedReturnDate}
          setDateSelectionInProgress={(inProgress) => this.setDateSelectionInProgress(inProgress)}
          toggleOverlay={() => this.toggleDateLayer()}
        >
          {!isLoading && showDateLayer && (
            <DatePicker
              changeDate={this.setDate}
              close={this.toggleDateLayer}
              dateSettings={siteSettings.datePicker}
              isHidden={!showDateLayer}
              isHotelOnly={false}
              labels={labels}
              range={this.getDateRange(selectedDuration.bookingType)}
              searchDirectOnly={searchDirectOnly}
              searchLuggageIncluded={searchLuggageIncluded}
              selectableDepartureDates={departureDates}
              selectedCabinClass={selectedCabinClass}
              selectedDepartureDate={selectedDepartureDate}
              selectedDuration={selectedDuration}
              selectedReturnDate={selectedReturnDate}
              setDateSelectionInProgress={this.setDateSelectionInProgress}
              setDepartureDateDuringSelection={this.setDepartureDateDuringSelection}
              setReturnDateDuringSelection={this.setReturnDateDuringSelection}
              setSearchDirectOnly={(toggle) => dispatch(FlightActions.setSearchDirectOnly(toggle))}
              setSearchLuggageIncluded={(toggle) =>
                dispatch(FlightActions.setSearchLuggageIncluded(toggle))
              }
              showDirectOnly={showDirectOnly}
              showLuggageIncluded={showLuggageIncluded}
              siteId={siteSettings.siteId}
            />
          )}
        </DateField>

        <PaxField
          isActive={showPaxLayer}
          isLoading={isLoading}
          isOtherFieldActive={!showPaxLayer && this.isSelectOverlayOpen()}
          labels={labels}
          roomDistribution={[selectedPassengerCombination]}
          selectedCabinClass={selectedCabinClass}
          toggleOverlay={() => this.togglePaxLayer()}
        >
          {!isLoading && (
            <PaxSelect
              activeValidation={!paxIsValid}
              changeRoomDistribution={(e, roomDistribution) =>
                this.setPassengerCombination(e, roomDistribution)
              }
              close={() => this.togglePaxLayer()}
              hideSameRoomCheckbox
              isDP={selectedDuration.bookingType === bookingTypes.dp}
              labels={labels}
              maxNumberOfPax={maxNumberOfPax.charter}
              multipleRooms={false}
              numberOfRooms={1}
              preferAllPaxInSameRoom={false}
              roomDistribution={[selectedPassengerCombination]}
              selectedCabinClass={selectedCabinClass}
              setCabinClass={(cabinClass) => dispatch(FlightActions.setCabinClass(cabinClass))}
              showCabinClasses={showCabinClasses}
            />
          )}
        </PaxField>

        {isMobile && this.renderOneWayFlightLink(isLoading)}

        <div className="bs-form__button-container">
          <Button
            className="bs-form__search-button"
            disabled={isLoading}
            onClick={() => this.searchFlight()}
            rel="nofollow"
            variant="primary"
          >
            {labels.searchButtonText}
          </Button>
        </div>

        {!isMobile && this.renderOneWayFlightLink(isLoading)}
      </div>
    );
  }
}

FlightSection.propTypes = {
  countryList: PropTypes.arrayOf(PropTypes.shape({})),
  departureAirportList: PropTypes.arrayOf(PropTypes.shape({})),
  departureDates: PropTypes.arrayOf(PropTypes.string),
  dispatch: PropTypes.func.isRequired,
  durationList: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  labels: PropTypes.shape().isRequired,
  oneWaySearchUrl: PropTypes.string,
  searchDirectOnly: PropTypes.bool,
  searchLuggageIncluded: PropTypes.bool,
  searchResultsDestination: PropTypes.shape({}),
  selectedCabinClass: PropTypes.string,
  selectedDepartureAirport: PropTypes.shape(),
  selectedDepartureDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  selectedDestination: PropTypes.shape(),
  selectedDuration: PropTypes.shape(),
  selectedPassengerCombination: PropTypes.shape(),
  selectedReturnDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  showCabinClasses: PropTypes.arrayOf(PropTypes.string),
  showDirectOnly: PropTypes.bool,
  showLuggageIncluded: PropTypes.bool,
  siteSettings: PropTypes.shape().isRequired,
};

FlightSection.defaultProps = {
  countryList: undefined,
  departureAirportList: undefined,
  departureDates: undefined,
  durationList: undefined,
  oneWaySearchUrl: undefined,
  searchDirectOnly: undefined,
  searchLuggageIncluded: undefined,
  searchResultsDestination: undefined,
  selectedCabinClass: undefined,
  selectedDepartureAirport: undefined,
  selectedDepartureDate: undefined,
  selectedDestination: undefined,
  selectedDuration: undefined,
  selectedPassengerCombination: undefined,
  selectedReturnDate: undefined,
  showCabinClasses: undefined,
  showDirectOnly: undefined,
  showLuggageIncluded: undefined,
};

function select(state) {
  return {
    countryList: state.flight.countryList,
    departureAirportList: state.flight.departureAirportList,
    departureDates: state.flight.departureDates,
    destinationAirportList: state.flight.destinationAirportList,
    durationList: state.flight.durationList,
    isLoading: state.flight.loading || !state.bookingstart.flightSectionInitialized,
    labels: state.bookingstart.labels,
    oneWaySearchUrl: state.flight.oneWaySearchUrl,
    returnDates: state.flight.returnDates,
    searchDirectOnly: state.flight.searchDirectOnly,
    searchHistory: state.flight.searchHistory,
    searchInformation: state.flight.searchInformation,
    searchLuggageIncluded: state.flight.searchLuggageIncluded,
    searchResultsDepartureAirport: state.flight.searchResultsDepartureAirport,
    searchResultsDestination: state.flight.searchResultsDestination,
    selectedCabinClass: state.flight.selectedCabinClass,
    selectedDepartureAirport: state.flight.selectedDepartureAirport,
    selectedDepartureDate: state.flight.selectedDepartureDate,
    selectedDestination: state.flight.selectedDestinationAirport,
    selectedDuration: state.flight.selectedDuration,
    selectedPassengerCombination: state.flight.selectedPassengerCombination,
    selectedReturnDate: state.flight.selectedReturnDate,
    showCabinClasses: state.flight.showCabinClasses,
    showDirectOnly: state.flight.showDirectOnly,
    showLuggageIncluded: state.flight.showLuggageIncluded,
    siteSettings: state.bookingstart.siteSettings,
  };
}

export default connect(select)(FlightSection);
