import PropTypes from 'prop-types';
import { useState } from 'react';
import { useIsGlobetrotter, useIsMobile } from '@bwoty-web/ui-kit/hooks';

import BSButton from '../bsButton/BSButton';
import DestinationSearch from './DestinationSearch';
import { DestinationSelectKeyDownHandler } from '../../utils/keyboardHandler';

const NUMBER_OF_COLUMNS = 3;

const DestinationSimpleSelect = ({
  destinationList,
  selectedDestination,
  searchDestinations,
  searchResult,
  labels,
  setDestination,
  setDestinationBySearch,
}) => {
  const isMobile = useIsMobile();
  const isGlobetrotter = useIsGlobetrotter();
  const [searchString, setSearchString] = useState('');

  const minDestinationsPerCol = Math.floor(destinationList.length / NUMBER_OF_COLUMNS);
  const rest = destinationList.length - minDestinationsPerCol * NUMBER_OF_COLUMNS;
  let amountOfRows = minDestinationsPerCol;
  if (rest) amountOfRows += 1;

  return (
    <div className="destination-select-container">
      {isGlobetrotter && !isMobile && (
        <h3 className="select-overlay__heading2">{labels.destinationLayerHeading}</h3>
      )}
      <div className="destination-select__search-n-destination">
        {searchDestinations && (
          <DestinationSearch
            onTextSearch={(searchTerm) => searchDestinations(searchTerm)}
            searchResult={searchResult}
            searchString={searchString}
            setSearchString={setSearchString}
            selectDestination={(e, destination) => setDestinationBySearch(e, destination)}
            labels={labels}
          />
        )}
        {!searchString && (
          <ul
            className="destination-select--simple"
            style={{ gridTemplateRows: `repeat(${amountOfRows}, 1fr)` }}
            onKeyDown={(e) => DestinationSelectKeyDownHandler(e, NUMBER_OF_COLUMNS, true)}
            role="listbox"
            tabIndex={-1}
          >
            {destinationList.map((destination, i) => {
              const colNr = Math.floor(i / amountOfRows);
              const isSelectedDestination = destination.itemId === selectedDestination.itemId;

              return (
                <li key={destination.itemId}>
                  <BSButton
                    data-col-nr={colNr}
                    name={destination.name}
                    onClick={(e) => setDestination(e, destination)}
                    selected={isSelectedDestination}
                  >
                    {destination.name}
                  </BSButton>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

DestinationSimpleSelect.propTypes = {
  destinationList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  labels: PropTypes.shape().isRequired,
  searchDestinations: PropTypes.func,
  searchResult: PropTypes.shape(),
  selectedDestination: PropTypes.shape().isRequired,
  setDestination: PropTypes.func.isRequired,
  setDestinationBySearch: PropTypes.func,
};

DestinationSimpleSelect.defaultProps = {
  searchDestinations: undefined,
  searchResult: undefined,
  setDestinationBySearch: undefined,
};

export default DestinationSimpleSelect;
