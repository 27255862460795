import moment from 'moment';
import bookingTypes from '../constants/bookingTypes';
import DESTINATION_TYPES from '../constants/destinationTypes';

export const getSelectedDuration = (durations, previousDuration) => {
  // 1. om samma reslängd och samma bokningstyp
  // 2. om samma reslängd
  // 3. försök välj charter.
  // 4. välj independent
  // 5. välj första i listan

  let selectedDuration = durations.find((x) => {
    return (
      x.defaultDuration === previousDuration.defaultDuration &&
      x.flexibleDuration === previousDuration.flexibleDuration &&
      x.bookingType === previousDuration.bookingType
    );
  });

  if (selectedDuration == null) {
    selectedDuration = durations.find((x) => {
      return (
        x.defaultDuration === previousDuration.defaultDuration &&
        x.flexibleDuration === previousDuration.flexibleDuration
      );
    });
  }

  if (selectedDuration == null) {
    selectedDuration = durations.find((x) => x.bookingType === bookingTypes.charter);
  }

  if (selectedDuration == null) {
    selectedDuration = durations.find((x) => x.flexibleDuration === true);
  }

  if (selectedDuration == null) {
    const [firstDuration] = durations;
    selectedDuration = firstDuration;
  }

  return selectedDuration;
};

export const getSelectedDepartureDate = (dates, selectedDate) => {
  if (dates.length === 0) {
    return selectedDate;
  }

  const date = moment(selectedDate);

  for (let i = 0; i < dates.length; i += 1) {
    if (!date.isAfter(moment(dates[i]), 'day')) {
      return dates[i];
    }
  }

  return dates[0];
};

export const getSelectedReturnDate = (departureDate, duration, prevReturnDate) => {
  const returnDate = duration.flexibleDuration
    ? moment(prevReturnDate)
    : moment(departureDate).add(duration.defaultDuration, 'days');

  if (duration.bookingType === bookingTypes.charter) {
    returnDate.subtract(1, 'days');
  }

  return returnDate.format('YYYY-MM-DD');
};

export const getSelectedPackageDestination = (countries, selectedDestination) => {
  // Choose all or first country per default
  let destination = countries[0].areaResorts[0];

  // Check if there are no sub-areas
  const hasNoSubAreas =
    (countries[0].areaResorts[0].itemId === -1 && countries[0].itemId === -1) ||
    (countries[0].name === countries[0].areaResorts[0].name &&
      countries[0].areaResorts[0].resorts?.length === 0);

  if (hasNoSubAreas) {
    [destination] = countries;
  }

  for (let i = 0; i < countries.length; i += 1) {
    // Check if selected destination is a country
    if (countries[i].itemId === selectedDestination.itemId && countries[i].itemId !== -1) {
      destination = countries[i];
      break;
    }

    if (countries[i].itemId === selectedDestination.countryId) {
      // Check if all alternative in country
      if (selectedDestination.itemId === -1) {
        // Get first selectable destination in country
        for (let x = 0; x < countries[i].areaResorts.length; x += 1) {
          const areaResort = countries[i].areaResorts[x];
          if (areaResort.isValidForSearch) {
            destination = areaResort;
            break;
          } else if (areaResort.resorts.length > 0 && areaResort.resorts[0].isValidForSearch) {
            const [firstDestination] = areaResort.resorts;
            destination = firstDestination;
            break;
          }
        }

        break;
      }

      for (let y = 0; y < countries[i].areaResorts.length; y += 1) {
        // Check if selected destination is resort or area under a country
        if (countries[i].areaResorts[y].itemId === selectedDestination.itemId) {
          destination = countries[i].areaResorts[y];
          break;
        }

        // Check if selected destination is a resort under an area
        if (countries[i].areaResorts[y].resorts.length > 0) {
          for (let x = 0; x < countries[i].areaResorts[y].resorts.length; x += 1) {
            if (countries[i].areaResorts[y].resorts[x].itemId === selectedDestination.itemId) {
              destination = countries[i].areaResorts[y].resorts[x];
              break;
            }
          }
        }
      }
    }
  }
  return destination;
};

export const getSelectedFlightOnlyDestination = (countries, selectedDestination) => {
  // Choose all or first country per default
  let destination = countries[0].destinationAirports[0];

  // Check if there are no sub-airports
  const hasNoSubAirports =
    countries[0].destinationAirports[0].itemId === -1 && countries[0].itemId === -1;

  if (hasNoSubAirports) {
    [destination] = countries;
  }

  for (let i = 0; i < countries.length; i += 1) {
    if (countries[i].itemId === selectedDestination.countryId) {
      for (let y = 0; y < countries[i].destinationAirports.length; y += 1) {
        if (countries[i].destinationAirports[y].itemId === selectedDestination.itemId) {
          destination = countries[i].destinationAirports[y];
          break;
        }
      }
    }
  }
  return destination;
};

const getFormattedDepDatesInRange = (departures, bookDaysAhead) => {
  let date = new Date();
  date.setDate(date.getDate() + bookDaysAhead);
  return departures
    .filter((departureDate) => moment(departureDate) > date)
    ?.map((departureDate) => moment(departureDate).format('YYYYMMDD'));
};

export const getFittingDepartureDate = (
  selectedDepartureAirport,
  firstAirport,
  departureDate,
  bookDaysAhead,
) => {
  let selectedDepartureDate = departureDate;
  if (selectedDepartureAirport) {
    const selectedAirportDepDates = getFormattedDepDatesInRange(
      selectedDepartureAirport.departureDates,
      bookDaysAhead,
    );

    // if departureDates for selected departure airport doesn't contain selected departureDate => set it to
    // the first one in range for the selected departure airport
    if (!departureDate || (departureDate && !selectedAirportDepDates?.includes(departureDate))) {
      if (selectedAirportDepDates && selectedAirportDepDates.length > 0) {
        selectedDepartureDate = selectedAirportDepDates[0];
      }
    }
  } else {
    // if no selected departure airport check and if first airport in list doesn't contain selected departureDate => set it to
    // the first one in range for the first airport
    if (firstAirport.departureDates && firstAirport.departureDates.length > 0) {
      const airportDepDates = getFormattedDepDatesInRange(
        firstAirport.departureDates,
        bookDaysAhead,
      );
      if (
        airportDepDates &&
        airportDepDates.length > 0 &&
        !airportDepDates?.includes(departureDate)
      ) {
        selectedDepartureDate = airportDepDates[0];
      }
    }
  }
  return selectedDepartureDate;
};

export const getPriceCalendarDataKey = (
  selectedDepartureAirportId,
  selectedDestinationId,
  selectedHotelId,
  selectedDuration,
  showDirectFlights,
) => {
  const duration = selectedDuration.defaultDuration + 1;
  const departureAirportId = selectedDepartureAirportId;
  const destinationId = selectedDestinationId || 0;
  const hotelId = selectedHotelId || 0;
  const priceCalendarKey = `${departureAirportId}_${destinationId}_${hotelId}_${duration}_${showDirectFlights}`;

  return priceCalendarKey;
};

export const getSelectedOffer = (priceCalendarKey, priceCalendarData, departureDate) => {
  const offers = priceCalendarData[priceCalendarKey]?.filter((month) =>
    moment(departureDate).isSame(month.month, 'month'),
  );
  return (
    offers?.[0]?.offers?.filter((offer) => {
      return moment(departureDate).isSame(offer.day, 'day');
    })?.[0] || null
  );
};

export const findSelectedDestinationAirportInRemappedData = (
  reMappedCountriesData,
  selectedDestinationAirport,
) => {
  let selectedAirport = null;
  reMappedCountriesData.some((country) => {
    return country.destinationAirports.some((airport) => {
      if (airport.itemId === selectedDestinationAirport.itemId) {
        selectedAirport = airport;
        return true;
      }
      return false;
    });
  });

  return selectedAirport;
};

export const findSelectedResortOrAreaInRemappedData = (reMappedCountriesData, selectedResort) => {
  let selectedDestination = null;
  reMappedCountriesData.some((country) => {
    return country.areaResorts.some((area) => {
      if (area.itemId === selectedResort.itemId) {
        selectedDestination = area;
        return true;
      }
      return area.resorts.some((resort) => {
        if (resort.itemId === selectedResort.itemId) {
          selectedDestination = resort;
          return true;
        }
        return false;
      });
    });
  });

  return selectedDestination;
};

const remapResorts = (resorts, areaId) =>
  resorts.map((resort) => ({
    ...resort,
    areaId,
    type: resort.itemId === -1 ? DESTINATION_TYPES.ALL_RESORTS : DESTINATION_TYPES.RESORT,
  }));

const areaTypeSelector = (area) => {
  if (area.itemId === -1) return DESTINATION_TYPES.ALL_AREAS;
  if (area?.isArea) return DESTINATION_TYPES.AREA;
  return DESTINATION_TYPES.RESORT;
};

const remapAreas = (areas) =>
  areas.map((area) => ({
    ...area,
    resorts:
      area?.isArea && area?.resorts.length > 0 ? remapResorts(area.resorts, area.itemId) : [],
    type: areaTypeSelector(area),
  }));

const remapAirports = (airports) =>
  airports.map((airport) => ({
    ...airport,
    type: airport.itemId === -1 ? DESTINATION_TYPES.ALL_AIRPORTS : DESTINATION_TYPES.AIRPORT,
  }));

export const remapCountries = (countries, productType = 'package') =>
  countries.map((country) => {
    const countryToReturn = {
      ...country,
      type: country.itemId === -1 ? DESTINATION_TYPES.ALL_COUNTRIES : DESTINATION_TYPES.COUNTRY,
    };

    if (productType === 'flight') {
      countryToReturn.destinationAirports =
        country?.destinationAirports.length > 0 ? remapAirports(country.destinationAirports) : [];
    } else {
      countryToReturn.areaResorts =
        country?.areaResorts.length > 0 ? remapAreas(country.areaResorts) : [];
    }

    return countryToReturn;
  });

export const remapCountriesData = (data, productType = 'package') => {
  const reMappedCountriesData = remapCountries(data.countries, productType);
  const reMappedCountriesDataToReturn = {
    ...data,
    countries: reMappedCountriesData,
  };

  if (productType === 'flight') {
    reMappedCountriesDataToReturn.selectedDestinationAirport =
      findSelectedDestinationAirportInRemappedData(
        reMappedCountriesData,
        data.selectedDestinationAirport,
      ) || data.selectedDestinationAirport;
  } else {
    reMappedCountriesDataToReturn.selectedResort =
      findSelectedResortOrAreaInRemappedData(reMappedCountriesData, data.selectedResort) ||
      data.selectedResort;
  }

  return reMappedCountriesDataToReturn;
};
