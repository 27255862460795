import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react'; // eslint-disable-line no-unused-vars

import FormFieldDate from '../formField/FormFieldDate';
import FormFieldLoader from '../formField/FormFieldLoader';

export default class DateField extends Component {
  constructor() {
    super();
    this.departureDateDuringSelection = null;
    this.returnDateDuringSelection = null;
  }

  componentWillReceiveProps(nextProps) {
    const {
      dateSelectionInProgress,
      departureDateDuringSelection,
      returnDateDuringSelection,
      selectedDepartureDate,
      selectedReturnDate,
      setDateSelectionInProgress,
    } = this.props;

    const selectionNoLongerInProgress =
      !nextProps.dateSelectionInProgress && dateSelectionInProgress;
    const retDateHasChanged = nextProps.selectedReturnDate != selectedReturnDate;
    const depDateHasChanged = nextProps.selectedDepartureDate != selectedDepartureDate;
    const depDateDuringSelectionHasChanged =
      nextProps.departureDateDuringSelection != departureDateDuringSelection;
    const retDateDuringSelectionHasChanged =
      nextProps.returnDateDuringSelection != returnDateDuringSelection;

    if (
      selectionNoLongerInProgress ||
      (nextProps.dateSelectionInProgress && (retDateHasChanged || depDateHasChanged))
    ) {
      setDateSelectionInProgress(false);
      this.departureDateDuringSelection = null;
      this.returnDateDuringSelection = null;
    } else if (depDateDuringSelectionHasChanged) {
      this.departureDateDuringSelection = nextProps.departureDateDuringSelection;
    } else if (retDateDuringSelectionHasChanged) {
      this.returnDateDuringSelection = nextProps.returnDateDuringSelection;
    }
  }

  render() {
    const {
      children,
      className,
      datePickerIsActive,
      dateSelectionInProgress,
      dateSettings,
      departureDateDuringSelection,
      hideReturnDate,
      hotelLabel,
      isActive,
      isHotelOnly,
      isLoading,
      isOtherFieldActive,
      labels,
      returnDateDuringSelection,
      selectedDepartureDate,
      selectedDuration,
      selectedMonths,
      selectedReturnDate,
      toggleOverlay,
    } = this.props;

    const label =
      hotelLabel ||
      (selectedMonths && selectedMonths.length > 0
        ? labels.monthDateFieldLabel
        : labels.dateInputLabel);

    if (isLoading) {
      return (
        <div className="bs-form__select bs-form__date-select">
          <FormFieldLoader label={label} />
        </div>
      );
    }

    const depDateDuringSelectionFormatted = moment(this.departureDateDuringSelection).format(
      dateSettings.shortDateFormat,
    );
    const depDateFormatted = moment(selectedDepartureDate).format(dateSettings.shortDateFormat);
    const returnDateFormatted = moment(selectedReturnDate).format(dateSettings.shortDateFormat);

    const showDateSelectIndication =
      selectedDuration && selectedDuration.flexibleDuration && datePickerIsActive;
    const departureDate = dateSelectionInProgress
      ? depDateDuringSelectionFormatted
      : depDateFormatted;

    let returnDate = null;
    if (dateSelectionInProgress && this.returnDateDuringSelection != null) {
      returnDate = moment(returnDateDuringSelection).format(dateSettings.shortDateFormat);
    } else if (dateSelectionInProgress && this.returnDateDuringSelection == null) {
      returnDate = isHotelOnly
        ? labels.datepickerHelpmessageHomeHotelOnly
        : labels.datepickerHelpmessageHome;
    } else {
      returnDate = returnDateFormatted;
    }

    const classNames = cx('bs-form__select', 'bs-form__date-select', className);

    return (
      <div className={classNames}>
        <FormFieldDate
          dateSettings={dateSettings}
          label={label}
          click={() => toggleOverlay()}
          isActive={isActive}
          isOtherFieldActive={isOtherFieldActive}
          dateSelectionInProgress={showDateSelectIndication}
          departureDateSelected={departureDateDuringSelection != null}
          departureDate={departureDate}
          returnDate={returnDate}
          hideReturnDate={hideReturnDate}
          selectedMonths={selectedMonths}
        />
        {children}
      </div>
    );
  }
}

DateField.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  datePickerIsActive: PropTypes.bool.isRequired,
  dateSelectionInProgress: PropTypes.bool,
  dateSettings: PropTypes.shape({
    shortDateFormat: PropTypes.string.isRequired,
  }).isRequired,
  departureDateDuringSelection: PropTypes.shape({}),
  hideReturnDate: PropTypes.bool,
  hotelLabel: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  isHotelOnly: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isOtherFieldActive: PropTypes.bool.isRequired,
  labels: PropTypes.shape({
    dateInputLabel: PropTypes.string.isRequired,
    datepickerHelpmessageHome: PropTypes.string,
    datepickerHelpmessageHomeHotelOnly: PropTypes.string,
    monthDateFieldLabel: PropTypes.string,
  }).isRequired,
  returnDateDuringSelection: PropTypes.shape({}),
  selectedDepartureDate: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.shape({})]),
  selectedDuration: PropTypes.shape({
    flexibleDuration: PropTypes.bool.isRequired,
  }),
  selectedMonths: PropTypes.arrayOf(PropTypes.shape({})),
  selectedReturnDate: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.shape({})]),
  setDateSelectionInProgress: PropTypes.func,
  toggleOverlay: PropTypes.func.isRequired,
};

DateField.defaultProps = {
  className: undefined,
  dateSelectionInProgress: undefined,
  departureDateDuringSelection: null,
  hideReturnDate: undefined,
  hotelLabel: undefined,
  isHotelOnly: false,
  returnDateDuringSelection: null,
  selectedDepartureDate: undefined,
  selectedDuration: undefined,
  selectedMonths: undefined,
  selectedReturnDate: undefined,
  setDateSelectionInProgress: undefined,
};
