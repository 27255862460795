import PropTypes from 'prop-types';
import FormField from '../formField/FormField';
import FormFieldLoader from '../formField/FormFieldLoader';

const DurationField = ({
  children,
  isActive,
  isLoading,
  isOtherFieldActive,
  labels,
  selectedDuration,
  showDurationField,
  toggleOverlay,
}) => {
  if (!showDurationField) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="bs-form__select bs-form__duration-select">
        <FormFieldLoader label={labels.durationInputLabel} />
      </div>
    );
  }

  return (
    <div className="bs-form__select bs-form__duration-select">
      <FormField
        value={selectedDuration.name}
        label={labels.durationInputLabel}
        click={() => toggleOverlay()}
        isActive={isActive}
        isOtherFieldActive={isOtherFieldActive}
      />
      {isActive ? children : null}
    </div>
  );
};

DurationField.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOtherFieldActive: PropTypes.bool.isRequired,
  labels: PropTypes.shape(),
  selectedDuration: PropTypes.shape({ name: PropTypes.string.isRequired }),
  showDurationField: PropTypes.bool.isRequired,
  toggleOverlay: PropTypes.func.isRequired,
};

DurationField.defaultProps = {
  children: null,
  labels: {},
  selectedDuration: undefined,
};

export default DurationField;
