import PropTypes from 'prop-types';
import { useIsGlobetrotter, useIsMobile } from '@bwoty-web/ui-kit/hooks';

import BSButton from '../bsButton/BSButton';
import { DestinationSelectKeyDownHandler } from '../../utils/keyboardHandler';

import './departureSelect.scss';

const NUMBER_OF_COLUMNS = 3;

const DepartureSelect = ({ heading, airportList, selectedAirport, changeAirport }) => {
  const isMobile = useIsMobile();
  const isGlobetrotter = useIsGlobetrotter();

  const minAirportsPerCol = Math.floor(airportList.length / NUMBER_OF_COLUMNS);
  const rest = airportList.length - minAirportsPerCol * NUMBER_OF_COLUMNS;
  let amountOfRows = minAirportsPerCol;
  if (rest) amountOfRows += 1;

  return (
    <div className="departure-select-container">
      {isGlobetrotter && !isMobile && <h3 className="select-overlay__heading2">{heading}</h3>}
      <ul
        className="departure-select"
        onKeyDown={(e) => DestinationSelectKeyDownHandler(e, NUMBER_OF_COLUMNS, true)}
        role="listbox"
        style={{ gridTemplateRows: `repeat(${amountOfRows}, 1fr)` }}
        tabIndex={-1}
      >
        {airportList.map((airport, i) => {
          const colNr = Math.floor(i / amountOfRows);
          const isSelectedAirport = airport.itemId === selectedAirport.itemId;

          return (
            <li key={airport.itemId}>
              <BSButton
                aria-label={airport.name}
                data-col-nr={colNr}
                name={airport.name}
                onClick={(e) => changeAirport(e, airport)}
                selected={isSelectedAirport}
              >
                {airport.name}
              </BSButton>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const tAirportList = {
  isCharter: PropTypes.bool,
  isIndependent: PropTypes.bool,
  itemId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

DepartureSelect.propTypes = {
  airportList: PropTypes.arrayOf(PropTypes.shape(tAirportList)).isRequired,
  changeAirport: PropTypes.func.isRequired,
  heading: PropTypes.string,
  selectedAirport: PropTypes.shape(tAirportList).isRequired,
};

DepartureSelect.defaultProps = {
  heading: '',
};

export default DepartureSelect;
