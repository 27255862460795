import { ActionTypes } from '../constants/actionTypes/bookingstart';

const cityReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.CITY_INIT_SECTION:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.CITY_INIT_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        departureAirportList: action.data.departures,
        selectedDepartureAirport: action.data.selectedDeparture,
        resortList: action.data.resorts,
        selectedResort: action.data.selectedResort,
        durationList: action.data.durations,
        departureDates: action.data.departureDates,
        returnDates: action.data.returnDates,
        departureMonths: action.data.departureMonths || [],
        selectedDuration: action.data.selectedDuration,
        selectedDepartureDate: action.data.selectedDepartureDate,
        selectedReturnDate: action.data.selectedReturnDate,
        isEveryoneInSameRoomChecked: action.data.everyOneInSameRoom,
        selectedNumberOfRooms: action.data.selectedNumberOfRooms,
        rooms: action.data.rooms,
        searchHistory: action.data.searchHistory,
        searchInformation: action.data.searchInformation,
        sectionName: 'city',
        showPriceCalendar: action.showPriceCalendar,
        showDirectFlights: action.showDirectFlights,
        priceCalendarDurationOptions: action.data.options,
        priceCalendarData: {},
        previousDuration: null,
        showDirectOnly: false,
        searchDirectOnly: false,
        showLuggageIncluded: false,
        searchLuggageIncluded: false,
        showCabinClasses: null,
        selectedCabinClass: null,
      };

    case ActionTypes.CITY_CHANGE_DEPARTURE_AIRPORT:
      return {
        ...state,
        selectedDepartureAirport: action.selectedDepartureAirport,
        departureMonths: [],
      };

    case ActionTypes.CITY_GET_RESORTS_SUCCESS:
      return {
        ...state,
        resortList: action.resorts,
        selectedResort: action.selectedResort,
      };

    case ActionTypes.CITY_SEARCH_DESTINATIONS_SUCCESS:
      return {
        ...state,
        searchResultsDestination: action.searchResults,
      };

    case ActionTypes.CITY_CHANGE_RESORT:
      return {
        ...state,
        selectedResort: action.selectedResort,
        departureMonths: [],
      };

    case ActionTypes.CITY_GET_DURATIONS_SUCCESS:
      return {
        ...state,
        durationList: action.durations,
        selectedDuration: action.selectedDuration,
        priceCalendarDurationOptions: action.options,
      };

    case ActionTypes.CITY_CHANGE_DURATION:
      return {
        ...state,
        selectedDuration: action.selectedDuration,
        departureMonths: [],
      };

    case ActionTypes.CITY_GET_DEPARTURE_DATES_SUCCESS:
      return {
        ...state,
        departureDates: action.departureDates,
        selectedDepartureDate: action.selectedDepartureDate,
        selectedReturnDate: action.selectedReturnDate,
      };

    case ActionTypes.CITY_CHANGE_DEPARTURE_DATE:
      return {
        ...state,
        selectedDepartureDate: action.selectedDepartureDate,
        selectedReturnDate: action.selectedReturnDate,
        selectedOffer: action.selectedOffer,
      };

    case ActionTypes.CITY_CHANGE_DATES:
      return {
        ...state,
        selectedDepartureDate: action.selectedDepartureDate,
        selectedReturnDate: action.selectedReturnDate,
      };

    case ActionTypes.CITY_CHANGE_ROOM_DISTRIBUTION:
      return {
        ...state,
        rooms: action.roomDistribution,
        selectedNumberOfRooms: action.selectedNumberOfRooms,
        isEveryoneInSameRoomChecked: action.isSameRoom,
      };

    case ActionTypes.CITY_CHANGE_DEPARTURE_MONTHS:
      return {
        ...state,
        departureMonths: action.departureMonths,
        selectedDepartureDate: action.selectedDepartureDate,
        selectedReturnDate: action.selectedReturnDate,
      };

    case ActionTypes.CITY_SET_SHOW_PRICE_CALENDAR:
      return {
        ...state,
        showPriceCalendar: action.showPriceCalendar,
      };

    case ActionTypes.CITY_SET_SHOW_DIRECT_FLIGHTS:
      return {
        ...state,
        showDirectFlights: action.showDirectFlights,
      };

    case ActionTypes.CITY_GET_PRICE_CALENDAR_DATA_SUCCESS:
      return {
        ...state,
        priceCalendarData: {
          ...action.priceCalendarData,
        },
      };

    case ActionTypes.CITY_SET_PREVIOUS_DURATION:
      return {
        ...state,
        previousDuration: action.previousDuration,
      };

    case ActionTypes.CITY_GET_EXTENDED_SEARCH_OPTIONS_SUCCESS:
      return {
        ...state,
        showDirectOnly: action.options?.displayDirectOnly,
        showLuggageIncluded: action.options?.displayBaggage,
        showCabinClasses: action.options?.displayCabinClasses,
        selectedCabinClass: action.options?.selectedCabinClass,
      };

    case ActionTypes.CITY_SET_CABINCLASS:
      return {
        ...state,
        selectedCabinClass: action.cabinclass,
      };

    case ActionTypes.CITY_SET_SEARCH_DIRECT_ONLY:
      return {
        ...state,
        searchDirectOnly: action.searchDirectOnly,
      };

    case ActionTypes.CITY_SET_SEARCH_LUGGAGE_INCLUDED:
      return {
        ...state,
        searchLuggageIncluded: action.searchLuggageIncluded,
      };

    default:
      return state;
  }
};

export default cityReducer;
