import cx from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from '@bwoty-web/ui-kit';
import { UI_CHEVRON_DOWN, UI_CHEVRON_UP } from '@bwoty-web/ui-kit/icons';

import './formField.scss';

const FormField = ({ className, click, isActive, isOtherFieldActive, label, value }) => (
  <button
    className={cx(className, 'form-field', {
      'form-field--active': isActive,
      'form-field--other-active': isOtherFieldActive,
    })}
    onClick={(event) => click(event)}
    type="button"
  >
    <Icon
      className="form-field__arrow"
      path={isActive ? UI_CHEVRON_UP : UI_CHEVRON_DOWN}
      size="xxxs"
    />
    <div className="form-field__label">{label}</div>
    <div className="form-field__value">{value}</div>
    <input type="hidden" value={value} />
  </button>
);

FormField.propTypes = {
  className: PropTypes.string,
  click: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  isOtherFieldActive: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
};

FormField.defaultProps = {
  className: null,
  isActive: false,
  isOtherFieldActive: false,
  label: null,
};

export default FormField;
